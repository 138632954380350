import React, { useEffect, useState } from "react"
import ReviewItemOld from "../../../features/review_item_old/ReviewItemOld"
import { useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/auth/authSlice"
import { skipToken } from "@reduxjs/toolkit/query"
import { Link } from "react-router-dom"
import avatarPlaceholder from "../../../assets/images/avatar_placeh.png"
import { useGetUserReviewsQuery } from "../../../app/services/base/reviews"
import { tryToParse } from "../../../utils/objectsUtils"
import { getUserAvatar } from "../../../utils/userUtils"

export const UserPage: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)

  const { data: reviewsShops } = useGetUserReviewsQuery(
    user ? { user_id: user.id, status: 1, page: 1, perPage: 10 } : skipToken,
  )

  const [structuredData, setStructuredData] = useState<any>()

  useEffect(() => {
    const parseData = async () => {
      if (user && user.structuredData) {
        const parsedData = await tryToParse(user.structuredData)
        setStructuredData(parsedData)
      }
    }

    if (user && user.structuredData) {
      parseData()
    }
  }, [user, user?.structuredData])

  if (!user) {
    return <></>
  }

  return (
    <div className="w-container utente vetrina white-background">
      <div className="page-user-top">
        <div className="avatar">
          <img
            alt={user.firstname}
            src={user ? getUserAvatar(user.id, true) : avatarPlaceholder}
            className="img-fluid rounded-circle h-100"
            onError={(e) => (e.currentTarget.src = avatarPlaceholder)}
          />
        </div>
        <div className="title">
          <h2>{`Le recensioni di ${user.firstname}`}</h2>
          <h4>
            {structuredData && structuredData.nazione
              ? structuredData.nazione
              : ""}
            {structuredData && structuredData.nazione && structuredData.citta
              ? ", "
              : " "}
            {structuredData && structuredData.citta ? structuredData.citta : ""}
          </h4>
        </div>
        <div className="details">
          <span className="details-item">
            <span className="pipe">
              {user.rank_position && user.rank_position > 0 ? (
                <Link
                  to={`/classifica-recensori?p=${user.id}`}
                  className="text-decoration-none"
                >
                  <span className="count">{user.rank_position}°</span>{" "}
                  {`in classifica con ${user.points} punti carriera`}
                </Link>
              ) : (
                "Non in classifica"
              )}
            </span>
          </span>
          <span className="details-item">
            <span className="pipe">
              <span className="count">{user.reviews}</span>{" "}
              {user.reviews == 1 ? "recensione" : "recensioni"}
            </span>
          </span>
          <span className="details-item">
            <span className="count">{user.visualization}</span>{" "}
            {user.visualization == 1 ? "visualizzazione" : "visualizzazioni"}
          </span>
        </div>
      </div>
      <div className="page-user-middle">
        {reviewsShops &&
          reviewsShops.items.map((reviewShop) => (
            <ReviewItemOld
              key={reviewShop.review.id}
              review={reviewShop.review}
              shop={reviewShop.eshop}
              activateActions={false}
            />
          ))}
      </div>
    </div>
  )
}

export default UserPage
