import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import {
  selectUnlockedCouponsModal,
  setUnlockedCouponsModal,
} from "./modalsSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useGetCouponsUserHistoryQuery } from "../../app/services/base/coupons"
import { Link } from "react-router-dom"
import { selectCurrentUser } from "../auth/authSlice"
import { skipToken } from "@reduxjs/toolkit/query"
import {
  formatNumber,
  formatToYearMonth,
  generateDatesFromMonthString,
  toStringFromDateTime,
} from "../../utils/stringUtils"

export const UnlockedCouponsModal: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const unlockedCouponsModal = useAppSelector(selectUnlockedCouponsModal)
  const dispatch = useAppDispatch()
  const dateArray = generateDateArray(new Date(), 16)

  const [selectedMonth, setSelectedMonth] = useState<string>(
    formatToYearMonth(new Date()),
  )
  const [firstDay, lastDay] = generateDatesFromMonthString(selectedMonth)
  const { data: unlockedCoupons } = useGetCouponsUserHistoryQuery(
    user?.id ? { userId: user.id, since: firstDay, until: lastDay } : skipToken,
  )

  function getDateString(date: Date) {
    return (
      date
        .toLocaleString(undefined, { month: "long" })
        .charAt(0)
        .toUpperCase() +
      date.toLocaleString(undefined, { month: "long" }).slice(1) +
      " " +
      date.getFullYear()
    )
  }

  function getPreviousMonth(date: Date) {
    const previousMonthDate = new Date(date)
    const currentMonth = previousMonthDate.getMonth()
    previousMonthDate.setMonth(currentMonth - 1)

    return previousMonthDate
  }

  function generateDateArray(startDate: Date, count: number) {
    const dateArray = [new Date(startDate)]

    for (let i = 1; i < count; i++) {
      const previousMonthDate = getPreviousMonth(dateArray[i - 1])
      dateArray.push(new Date(previousMonthDate))
    }

    return dateArray
  }

  return (
    <>
      <Modal
        show={unlockedCouponsModal}
        onHide={() => dispatch(setUnlockedCouponsModal(false))}
        size="xl"
        centered
        aria-labelledby="unlockedCouponsModalLabel"
        dialogClassName="justify-content-center"
        contentClassName="bg-transparent position-relative border-0"
      >
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
          <div className="card p-3 px-lg-5 w-100">
            <div className="d-flex flex-column align-items-center justify-content-center border-0">
              <button
                type="button"
                className="btn-close btn-close-absolute d-none d-lg-block position-absolute p-0"
                onClick={() => dispatch(setUnlockedCouponsModal(false))}
              ></button>
            </div>
            <div className="d-flex flex-column justify-content-center justify-content-lg-start align-items-center align-items-lg-start py-1 px-2">
              <form
                action={`${
                  import.meta.env.VITE_B2C_BASE_URL
                }it/areariservata/couponHistory`}
                method="get"
                className="filtro-history w-100"
              >
                <label className="d-block d-lg-inline-block dark-color font-18">
                  Mostra risultati nel mese di
                </label>
                <select
                  className="form-control d-block d-lg-inline-block w-auto mx-auto mx-lg-2"
                  name="mese"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {dateArray.map((date) => (
                    <option
                      key={formatToYearMonth(date)}
                      value={formatToYearMonth(date)}
                    >
                      {getDateString(date)}
                    </option>
                  ))}
                </select>
              </form>
            </div>
            <div className="d-flex align-items-center justify-content-start border-0 gap-1 my-3">
              <div className="modal-history-coupon w-100 overflow-scroll hide-scrollbar">
                {unlockedCoupons && unlockedCoupons?.purchases.length > 0 ? (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Emesso da</th>
                        <th scope="col">Valore</th>
                        <th className="text-center" scope="col">
                          Codice
                        </th>
                        <th scope="col">Data sblocco</th>
                        <th scope="col">Scadenza</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unlockedCoupons.purchases.map((couponPurchase) => (
                        <tr key={couponPurchase.id}>
                          <th scope="row">
                            <Link
                              to={`/eshop/${couponPurchase.domain}`}
                              className="dark-color orange-hover text-decoration-none"
                              target="_blank"
                            >
                              {couponPurchase.domain}
                            </Link>
                          </th>
                          <td>
                            {formatNumber(Number(couponPurchase.discount), 0)}
                            {couponPurchase.discount_type === 1 ? "%" : "€"}
                          </td>
                          <td className="dark-color table-warning font-semi-bolder text-center">
                            {couponPurchase.code}
                          </td>
                          <td className="dark-color">
                            {toStringFromDateTime(couponPurchase.purchase_date)}
                          </td>
                          <td className="dark-color">
                            {toStringFromDateTime(couponPurchase.expire_date)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="dark-color font-semi-bolder font-18 mt-3 px-2">
                    Nessun coupon sbloccato nel mese di{" "}
                    {getDateString(
                      new Date(
                        Number.parseInt(selectedMonth.split("-")[0]),
                        Number.parseInt(selectedMonth.split("-")[1]) - 1,
                      ),
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center d-lg-none mt-4">
            <button
              type="button"
              className="btn-close btn-close-white"
              aria-label="Close"
              onClick={() => dispatch(setUnlockedCouponsModal(false))}
            ></button>
          </div>
        </div>
      </Modal>
    </>
  )
}
