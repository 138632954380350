import React, { ChangeEvent, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useNavigate } from "react-router-dom"
import {
  useDeletePendingUpdateMutation,
  useUpdateReviewMutation,
  useUploadReceiptMutation,
  useUpdateProductReviewMutation,
  useDeletePendingProductUpdateMutation,
} from "../../../app/services/base/reviews"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { addToast } from "../../../features/toasts/toastsSlice"
import { ReviewShopUserUnion } from "../../../models/base/reviewShopUserUnion.interface"
import checkOrange from "../../../assets/icons/check-orange.svg"
import infoSvg from "../../../assets/icons/info.svg"
import { toStringFromDateTime } from "../../../utils/stringUtils"
import { tryToParse } from "../../../utils/objectsUtils"
import imagePlaceholder from "../../../assets/images/thumb_placeholder.webp"
import {
  selectUpdateReviewModal,
  setUpdateReviewModal,
} from "../../../features/modals/modalsSlice"
import { UpdateReviewModal } from "../../../features/modals/UpdateReviewModal"
import { Button } from "react-bootstrap"

interface ReviewsModifyUpdateProps {
  data: ReviewShopUserUnion
}

interface ReviewsModifyFormData {
  title: string
  description: string
  scores: Record<string, number>
  file: File | undefined
}

const infoTexts: Record<string, string> = {
  "Chiarezza del sito web":
    "Il sito web visitato è chiaro e di semplice navigabilità nel suo complesso? Hai trovato facilmente ciò che cercavi?",
  "Valutazione prodotto/servizio":
    "Come valuti il prodotto o il servizio acquistato in ordine alla qualità e al prezzo?",
  "Servizio di pagamento":
    "E' stato facile effettuare il pagamento? Hai trovato il metodo di pagamento che preferisci?",
  "Consegna/Reso": "La consegna è avvenuta entro i tempi promessi?",
  "Assistenza clienti":
    "Se ne hai usufruito, gli operatori dell'assistenza clienti sono stati disponibili e cortesi? La problematica esposta è stata risolta? Se hai dovuto effettuare un reso, come è andata l'operazione in termini di semplicità e velocità?",
  "Lo consiglieresti a qualcuno?":
    "Sulla base della tua esperienza generale di acquisto consiglieresti a qualcuno questo ecommerce?",
}

const ratings = [
  { value: 1, text: "Pessimo" },
  { value: 2, text: "Scarso" },
  { value: 3, text: "Nella media" },
  { value: 4, text: "Molto buono" },
  { value: 5, text: "Eccellente" },
]

export const ReviewsModifyUpdate: React.FC<ReviewsModifyUpdateProps> = ({
  data,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [deletePendingUpdate] = useDeletePendingUpdateMutation()
  const [deletePendingProductUpdate] = useDeletePendingProductUpdateMutation()
  const [updateReview] = useUpdateReviewMutation()
  const [updateProductReview] = useUpdateProductReviewMutation()
  const [uploadReceipt, { error }] = useUploadReceiptMutation()
  const updateReviewModal = useAppSelector(selectUpdateReviewModal)

  let reviewReceipt: boolean | Array<any> = false
  let countReceipt: number = 0
  if (data.review.receipt !== null && data.review.receipt !== "") {
    try {
      reviewReceipt = JSON.parse(data.review.receipt)
      countReceipt = Array.isArray(reviewReceipt) ? reviewReceipt.length : 0
    } catch (error) {
      console.error("Error parsing receipt:", data.review.receipt)
    }
  }

  const [formData, setFormData] = useState<ReviewsModifyFormData>({
    title: data.review.title,
    description: data.review.description,
    scores: {},
    file: undefined,
  })

  const overall_rating = formData.scores.overall_rating
    ? Number(formData.scores.overall_rating)
    : parseInt(data.review.overall_rating)

  async function handleDeletePendingUpdate(reviewId: number) {
    try {
      const response =
        data.review.is_prod_review == 1
          ? await deletePendingProductUpdate(reviewId).unwrap()
          : await deletePendingUpdate(reviewId).unwrap()

      if (!response.success) throw new Error()

      dispatch(
        addToast({
          message: "Modifica in attesa eliminata correttamente.",
          type: "success",
        }),
      )
    } catch (err) {
      dispatch(
        addToast({
          message:
            "Ops! Impossibile annullare la richiesta di modifica al momento.",
          type: "danger",
        }),
      )
    }
  }

  function handleInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = e.currentTarget
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  function updateRating(key: string, value: string) {
    setFormData({
      ...formData,
      scores: { ...formData.scores, [key]: Number(value) },
    })
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0]
      setFormData({
        ...formData,
        file: file,
      })
    }
  }

  function handleUpdateReviewClick() {
    dispatch(setUpdateReviewModal(true))
  }

  async function handleUpdateReview() {
    try {
      debugger
      const response =
        data.review.is_prod_review == 1
          ? await updateProductReview({
              id: data.review.id,
              title: formData.title,
              description: formData.description,
              rating: formData.scores,
            }).unwrap()
          : await updateReview({
              id: data.review.id,
              title: formData.title,
              description: formData.description,
              rating: formData.scores,
            }).unwrap()

      if (formData.file) {
        const request = new FormData()
        request.append("review_id", data.review.id.toString() || "")
        request.append("files", formData.file, formData.file.name)
        const response = await uploadReceipt(request).unwrap()
      }

      if (!response.success) throw new Error()

      navigate("/areariservata/reviews-modify-ok")
      setTimeout(() => {
        navigate("/areariservata/reviews")
      }, 3000)
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Impossibile modificare la recensione al momento.",
          type: "danger",
        }),
      )
    }
  }

  useEffect(() => {
    const parseData = async () => {
      if (data && data.review.details_rating) {
        const parsedData = await tryToParse(data.review.details_rating)
        setFormData({ ...formData, scores: parsedData })
      }
    }

    if (data) {
      parseData()
    }
  }, [data])

  return (
    <div className="w-container utente update-review white-background">
      <form
        id="review-form"
        className="horizontal-form form form-link form-link-u"
      >
        <div className="review-ins-container review-item">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              id="step2"
            >
              {data.review.pendingUpdate.updateDate && (
                <>
                  <p className="text-danger" style={{ fontSize: "14px" }}>
                    La tua modifica{" "}
                    {data.review.pendingUpdate.updateDate
                      ? `del
                    ${toStringFromDateTime(
                      data.review.pendingUpdate.updateDate,
                    )}`
                      : ""}{" "}
                    è in analisi. Potrai modificare nuovamente la recensione
                    quando la modifica verrà approvata o rifiutata
                  </p>
                  <p>
                    <a
                      onClick={() => handleDeletePendingUpdate(data.review.id)}
                      className="delete_rec_cta"
                    >
                      {" "}
                      Elimina la modifica in attesa{" "}
                    </a>
                  </p>
                </>
              )}
              <div className="form-group">
                {data.review.is_prod_review == 0 ? (
                  <input
                    rel="step4"
                    className="form-control verify"
                    disabled={true}
                    placeholder="Sintetizza in una frase la tua esperienza d’acquisto (max 50 caratteri)"
                    maxLength={50}
                    name="es"
                    id="ReviewUpdateForm_es"
                    type="text"
                    value={data.eshop.domain}
                  ></input>
                ) : (
                  <>
                    <span className="rev-prod-eshop">
                      {data.eshop.domain}
                      {data.eshop.status == 1 && <img src={checkOrange} />}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              id="step3"
            >
              <div className="form-group">
                {data.review.is_prod_review == 0 ? (
                  <input
                    rel="step4"
                    className="form-control verify"
                    placeholder="Sintetizza in una frase la tua esperienza d’acquisto (max 50 caratteri)"
                    maxLength={50}
                    name="title"
                    id="ReviewUpdateForm_titolo"
                    type="text"
                    value={formData.title}
                    onChange={handleInputChange}
                    disabled={
                      data.review.pendingUpdate.updateDate ? true : false
                    }
                  ></input>
                ) : (
                  <div className="rev-prod-desc clearfix">
                    {data.review.prod_review?.img_prod && (
                      <div className="rev-prod-img">
                        <img
                          src={data.review.prod_review.img_prod}
                          onError={(e) =>
                            (e.currentTarget.src = imagePlaceholder)
                          }
                        />
                      </div>
                    )}

                    <div className="rev-prod-name">
                      {data.review.prod_review?.name_prod}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              id="step4"
            >
              <div className="form-group">
                <textarea
                  rel="step5"
                  className="form-control verify rev-desc"
                  placeholder="Sii il più dettagliato possibile al fine di fornire un contributo di qualità agli altri consumatori online. (min 50 caratteri/max 500)"
                  maxLength={500}
                  minLength={50}
                  name="description"
                  id="ReviewUpdateForm_descrizione"
                  disabled={data.review.pendingUpdate.updateDate ? true : false}
                  value={formData.description}
                  onChange={handleInputChange}
                ></textarea>
                <span className="counter">
                  caratteri rimanenti: {500 - formData.description.length}
                </span>
                {/* <?php echo $form->error($model,'descrizione');?> */}
              </div>
            </div>
          </div>

          {data.review.replies && (
            <>
              {data.review.replies.map(
                ({ reply, reply_date, reply_private }) => (
                  <div key={reply} className="row">
                    <div
                      className={
                        reply_private == 0
                          ? "col-md-12 col-xs-12"
                          : "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      }
                    >
                      <div className="review-reply">
                        <section>
                          <h3 className="reply-title">Risposta dell'eshop</h3>
                          <article>{reply}</article>
                        </section>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </>
          )}

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div
                className={`container-rating-main ${
                  data.review.is_prod_review == 1 ? "rating-prod" : ""
                }`}
              >
                {data.review.is_prod_review == 0 ? (
                  <>
                    {Object.entries(formData.scores)?.map(([key, value]) => (
                      <div key={key} className="container-rating">
                        <div className="form-group">
                          <div className="box-body">
                            <div className="box-header">
                              <span
                                data-toggle="pop-over"
                                data-content={infoTexts[key]}
                                data-original-title=""
                                title=""
                              >
                                <img src={infoSvg} />
                              </span>{" "}
                              {key.replace(/\\\//g, "/")}
                            </div>
                            <label
                              className="sr-only required"
                              htmlFor={`ReviewUpdateForm_${key}`}
                            >
                              {key} <span className="required">*</span>
                            </label>
                            <div className="br-wrapper br-theme-bars-movie">
                              <select
                                className="form-control"
                                name={key}
                                id={`ReviewUpdateForm_${key}`}
                                style={{ display: "none" }}
                                value={
                                  formData.scores[
                                    key as keyof ReviewsModifyFormData
                                  ]
                                }
                              >
                                <option value="">Vota</option>
                                <option value="0"></option>
                                {ratings.map((r) => (
                                  <option key={r.value} value={r.value}>
                                    {r.text}
                                  </option>
                                ))}
                              </select>
                              <div className="br-widget">
                                <div className="br-current-rating br-selected-5 br-active-3 br-active-2 br-active-1 br-active-4 br-active-5">
                                  <em>Eccellente</em>
                                </div>
                                {ratings.map((r) => (
                                  <a
                                    key={r.text}
                                    title={r.text}
                                    className={`${
                                      Number(
                                        formData.scores[
                                          key as keyof ReviewsModifyFormData
                                        ],
                                      ) >= r.value
                                        ? `br-selected-${
                                            formData.scores[
                                              key as keyof ReviewsModifyFormData
                                            ]
                                          }`
                                        : ""
                                    } ${
                                      Number(
                                        formData.scores[
                                          key as keyof ReviewsModifyFormData
                                        ],
                                      ) == r.value
                                        ? "br-current"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      updateRating(key, r.value.toString())
                                    }
                                  >
                                    <FontAwesomeIcon icon={["fas", "star"]} />
                                  </a>
                                ))}
                              </div>
                            </div>
                            {/* <?php echo $form->labelEx($model,$item, ['className' => 'sr-only']); ?>
    									<?php echo $form->dropdownList($model,$item, [''=> Yii::t('review', 'vota')]+ $model->rangeList, ['className'=>'form-control']); ?> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div key="overall_rating" className="container-rating">
                    <div className="form-group">
                      <div className="box-body">
                        <div className="box-header">
                          Valutazione complessiva
                        </div>
                        <label
                          className="sr-only required"
                          htmlFor={`ReviewUpdateForm_overall_rating`}
                        >
                          Valutazione complessiva{" "}
                          <span className="required">*</span>
                        </label>
                        <div className="br-wrapper br-theme-bars-movie">
                          <select
                            className="form-control"
                            name="overall_rating"
                            id={`ReviewUpdateForm_overall_rating`}
                            style={{ display: "none" }}
                            value={
                              formData.scores[
                                "overall_rating" as keyof ReviewsModifyFormData
                              ]
                            }
                          >
                            <option value="">Vota</option>
                            <option value="0"></option>
                            {ratings.map((r) => (
                              <option key={r.value} value={r.value}>
                                {r.text}
                              </option>
                            ))}
                          </select>
                          <div className="br-widget">
                            <div className="br-current-rating br-selected-5 br-active-3 br-active-2 br-active-1 br-active-4 br-active-5">
                              <em>Eccellente</em>
                            </div>
                            {ratings.map((r) => (
                              <a
                                key={r.text}
                                title={r.text}
                                className={`${
                                  overall_rating >= r.value
                                    ? `br-selected-${overall_rating}`
                                    : ""
                                } ${
                                  overall_rating == r.value ? "br-current" : ""
                                }`}
                                onClick={() =>
                                  updateRating(
                                    "overall_rating",
                                    r.value.toString(),
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={["fas", "star"]} />
                              </a>
                            ))}
                          </div>
                        </div>
                        {/* <?php echo $form->labelEx($model,$item, ['className' => 'sr-only']); ?>
    									<?php echo $form->dropdownList($model,$item, [''=> Yii::t('review', 'vota')]+ $model->rangeList, ['className'=>'form-control']); ?> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {data.review.certified != 2 && (
            <div className="col-12 col-sm-12 ccol-md-12 col-lg-12 col-xl-12">
              <div className="row">
                <div className="ucont edit">
                  <div className="row">
                    <div className="col-12 col-sm-12 ccol-md-12 col-lg-12 col-xl-12">
                      <div className="ckuc">
                        Dai valore alla tua recensione! Carica qui la tua prova
                        d'acquisto per verificare la tua recensione. Gli altri
                        consumatori sapranno che si tratta di una recensione
                        reale scritta a seguito di un acquisto verificato.
                      </div>
                    </div>
                  </div>
                  {reviewReceipt && (
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <div className="alr">
                          <FontAwesomeIcon
                            icon={["fas", "info-circle"]}
                            className="me-2"
                          />
                          Hai già inserito la{" "}
                          <strong>
                            <a
                              href={
                                import.meta.env.VITE_CDN_BASE_URL +
                                `/reviewsReceipt/${data.review.id}/${
                                  (reviewReceipt as any)[countReceipt - 1]
                                }`
                              }
                              target="_blank"
                            >
                              prova d'acquisto
                            </a>
                          </strong>
                        </div>
                      </div>
                    </div>
                  )}
                  {data.review.certified && (
                    <div className="row row">
                      <div className="col-md-12">
                        <div className="alert alert-success text-center">
                          Recensione verificata
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div
                      className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 u1 ux"
                      style={{ position: "relative" }}
                    >
                      <input
                        className="inputfile"
                        name="file"
                        id="ReviewUpdateForm_file"
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={handleFileChange}
                      />{" "}
                      <label
                        htmlFor="ReviewUpdateForm_file"
                        className="btn btn-home btn-flink2"
                      >
                        Seleziona file<span></span>
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 u2 ux">
                      <span className="ftg">{formData.file?.name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-sm-12 col-xs-12">
            <div className="review-actions update-rev">
              <Link
                className="btn btn-update btnbk pull-leftc"
                to={`/areariservata/${
                  data.review.is_prod_review == 1
                    ? "product-reviews"
                    : "reviews"
                }`}
              >
                <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                Torna alle recensioni
              </Link>{" "}
              <div className="float-right review-right-actions mt-3 mt-lg-0">
                <Button
                  name="update"
                  className="btn btn-gradient-orange"
                  onClick={handleUpdateReviewClick}
                  disabled={data.review.pendingUpdate.updateDate ? true : false}
                >
                  Conferma modifica
                </Button>
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
        </div>
      </form>
      {updateReviewModal && (
        <UpdateReviewModal
          callback={() => {
            handleUpdateReview()
          }}
        />
      )}
    </div>
  )
}
