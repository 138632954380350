import React, { useState } from "react"
import { Shop } from "../../../models/base/shop.interface"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { removeHtmlTags } from "../../../utils/stringUtils"
import { isPremiumShop, getShopLogo } from "../../../utils/shopUtils"
import { useGetFeedInfoQuery } from "../../../app/services/esaScraper/general"
import thumbPlaceholder from "../../../assets/images/thumb_placeholder.png"

interface EShopDetailsCardProps {
  shop: Shop
}

export const EShopDetailsCard: React.FC<EShopDetailsCardProps> = ({ shop }) => {
  const [showDescription, setShowDescription] = useState<boolean>(false)

  const { data: feedInfo } = useGetFeedInfoQuery({
    domainId: shop.id,
    kindOfFeed: "product",
  })

  let eshopUrl =
    (shop.eshopData.url.includes("http") ? "" : "http://") + shop.eshopData.url
  return (
    <div
      className={`card eshop-custom-card mb-0 mb-lg-2 me-2 me-lg-0 ${
        showDescription ? "mobile-height-auto" : ""
      }`}
    >
      {shop.plan == "ibrido" && (
        <div className="card-body d-flex flex-column justify-content-start px-3 py-4 p-lg-4 pb-lg-0">
          <span className="font-16 dark-color border-bottom d-block pb-3 mb-3 font-bolder">
            {shop.domain} è il tuo negozio online?
          </span>
          <p className="font-14 dark-color">
            Gestisci la tua vetrina gratuita su eShoppingAdvisor ed entra a far
            parte della nostra community. Consulta le recensioni sul tuo eshop e
            scopri cosa pensano i tuoi clienti.
          </p>
          <Link
            to={`https://business.eshoppingadvisor.com/registrazione/eshop?i=${shop.id}`}
            className="btn btn-gradient-blue d-block font-16 font-light py-2"
            target="_blank"
          >
            Reclama la tua vetrina
          </Link>
          <span className="d-block border-bottom pb-4 mb-4"></span>
        </div>
      )}
      {(getShopLogo(shop.id) && shop.plan != "ibrido") ? (
        <>
          <img
            src={getShopLogo(shop.id)}
            className="card-img-top d-none d-lg-block mb-3"
            alt={shop.domain}
            loading="lazy"
            onError={(e) => (e.currentTarget.src = thumbPlaceholder)}
          />
          <hr className="border border-dark d-none d-lg-block m-0 me-4 ms-4" />
        </>
      ): <></>}
      <div className="card-body d-flex flex-column justify-content-start px-3 py-4 p-lg-4">
        <div>
          <Link
            to={eshopUrl}
            target="_blank"
            className="d-block card-link text-decoration-none order-1"
            {...(isPremiumShop(shop.plan) ? {} : { rel: "nofollow" })}
          >
            <h3 className="bolder-dark-title mb-2 font-16 orange-hover">
              {shop.domain}
            </h3>
          </Link>
          {feedInfo && feedInfo.product_count && feedInfo.product_count > 0 ? (
            <Link
              to={eshopUrl}
              target="_blank"
              className="flex-grow-1 btn btn-outline-animation btn-outline-gradient-blue btn-custom-outline-blue d-flex justify-content-between align-items-center font-14 mb-lg-4 order-3 order-lg-2"
              {...(isPremiumShop(shop.plan) ? {} : { rel: "nofollow" })}
            >
              <span
                className="d-flex flex-wrap text-start underline me-3 line-clamp break-all"
                style={{ whiteSpace: "normal" }}
              >
                Visita il sito
              </span>
              <span>
                <FontAwesomeIcon
                  icon={["fas", "arrow-right-long"]}
                  className="text-center align-middle thin-icon font-18"
                />
              </span>
            </Link>
          ) : (
            <> </>
          )}
        </div>
        {/* TODO - Con la nuova traduzione, deve essere modificato, per gestire i cookies di GoogleTranslate */}
        {/* {shop.relatedLangs &&
        <div className="eshop-lang-selector dropdown mb-3 d-flex justify-content-end">
								 	<span className="dropdown-toggle d-block" data-toggle="dropdown">
									 	<img src="<?=Yii::app()->theme->baseUrl?>/assets/img/flags-mini/it.png" width="22,5" height="15" loading="lazy" alt="it" className="img-fluid">
								  	</span>
									<ul className="dropdown-menu">
										<li className="lang-item" data-text-lang="IT" data-lang-selected="1">
											<img src="<?=Yii::app()->theme->baseUrl?>/assets/img/flags-mini/it.png" width="22,5" height="15" loading="lazy" alt="it" className="img-fluid">
									  	<?php $textList['IT'] = strip_tags($eshop->content); ?>
								  	</li>
								  	<?php foreach($relatedLangs as $k => $lang):?>
									  	<li className="lang-item" data-text-lang="<?=$k?>" data-lang-selected="0">
										  	<img src="<?=Yii::app()->theme->baseUrl?>/assets/img/flags-mini/<?=strtolower($k)?>.png" width="22" height="15" alt="<?= $k ?>" loading="lazy" className="img-fluid">
											</li>
											<?php $textList[$k] = $lang; ?>
										<?php endforeach;?>
								  	</ul>
							  	</div>} */}
        <div
          className={`font-lighter dark-color font-14 mb-3 mb-lg-0 order-2 order-lg-3 mobile-height-125 ${
            showDescription ? "" : "mobile-height-125"
          }`}
        >
          <p
            className={`mt-3 mt-lg-0 line-clamp-6 lg-line-clamp-15 mb-0 ${
              showDescription ? "" : "line-clamp"
            }`}
          >
            {shop.eshopData.description &&
              removeHtmlTags(shop.eshopData.description)}
          </p>
          <span
            className={`simple-blue-link cursor-pointer ${
              showDescription ? "d-none" : ""
            }`}
            onClick={() => setShowDescription(true)}
          >
            Leggi tutto
          </span>
        </div>
      </div>
    </div>
  )
}
