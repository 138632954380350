import React from "react"
import customCss from "./advantages.css?inline"
import vantaggi1 from "../../assets/images/advantages/vantaggi_1.png"
import vantaggi2 from "../../assets/images/advantages/vantaggi_2.png"
import vantaggi3 from "../../assets/images/advantages/vantaggi_3.png"
import vantaggiHeader from "../../assets/images/advantages/vantaggi_header.jpg"
import vantaggiHeaderMobile from "../../assets/images/advantages/vantaggi-mobile.jpg"
import vantaggiBottom from "../../assets/images/advantages/vantaggi-bottom.jpg"
import vantaggiVideo from "../../assets/images/advantages/vantaggi-video.png"
import { Link } from "react-router-dom"
import useWindowSize from "../../utils/useWindowSize"
import { Helmet } from "react-helmet"

export const Advantages: React.FC = () => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width && windowSize.width <= 768
  return (
    <>
      <Helmet>
        <title>Scopri tutti i vantaggi di eShoppingAdvisor</title>
        <meta
          name="description"
          content="Registrati gratuitamente su eShoppingAdvisor: ecco tutti i vantaggi."
        />
      </Helmet>

      <section className="vantaggi-page">
        <div
          className="vantaggi-header"
          style={
            isMobile
              ? { backgroundImage: `url(${vantaggiHeaderMobile})` }
              : { backgroundImage: `url(${vantaggiHeader})` }
          }
        >
          <h1>
            Registrati gratuitamente su eShoppingAdvisor:
            <br /> ecco tutti i vantaggi.
          </h1>
        </div>
        <div className="box-vantaggi">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="title">Sii volpe</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div className="vantaggi-img">
                  <img src={vantaggi1} className="img-fluid" />
                </div>
                <div className="vantaggi-text">
                  Sfrutta l’esperienza degli altri utenti per scoprire i
                  migliori negozi online dove acquistare{" "}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div className="vantaggi-img">
                  <img src={vantaggi2} className="img-fluid" />
                </div>
                <div className="vantaggi-text">
                  Accedi a vantaggi e sconti a te riservati{" "}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div className="vantaggi-img">
                  <img src={vantaggi3} className="img-fluid" />
                </div>
                <div className="vantaggi-text">
                  Diventa micro influencer nella community, fai carriera con i
                  tuoi acquisti{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="vantaggi-bottom"
          style={{ backgroundImage: `url(${vantaggiBottom})` }}
        >
          <div className="container">
            <div className="box-video">
              <Link
                to="https://youtu.be/TBKGAFVc0h8"
                className="fancybox-media"
              >
                <img src={vantaggiVideo} className="img-fluid" />
              </Link>
            </div>
            <h3 className="bottom-text">
              Cosa aspetti? <br /> Condividi la tua esperienza!{" "}
            </h3>
            <div className="bottom-link">
              <Link to="/registrazione/cliente">Registrati ora</Link>
            </div>
          </div>
        </div>
        <style>{customCss}</style>
      </section>
    </>
  )
}
