import React, { useRef } from "react"
import OwlCarousel from "react-owl-carousel"
import { CategoryBadge } from "./CategoryBadge"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useGetCategoriesQuery } from "../../../app/services/base/categories"

export const CategoriesCarousel: React.FC = () => {
  const { data: categories } = useGetCategoriesQuery({ type: "visited" })
  const owlCarouselRef = useRef<OwlCarousel>(null)

  const handleNext = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.next(650)
    }
  }

  const handlePrev = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.prev(650)
    }
  }

  if (!categories) {
    return <></>
  }

  return (
    <div
      // id="headerCategoriesSlider"
      className="d-block xs-light-background dark-fade-background"
    >
      <div className="container">
        <div className="row pt-3 align-items-center">
          <div className="col-12 col-md-6 col-lg-8 my-0 my-md-3 py-3  d-flex align-items-center justify-content-start">
            <h3 className="xs-dark-color xs-font-23 bolder-white-title my-1">
              Scopri le categorie più visitate
            </h3>
          </div>
          <div className="col-12 col-md-6 col-lg-4 my-3 py-3 align-items-center justify-content-end d-none d-md-flex">
            <Link to="/categorie" className="btn btn-blur px-4 py-2 mx-3">
              {" "}
              Vedi tutte{" "}
            </Link>
            <span
              // id="categories-prev"
              className="carousel-control white-color mx-2"
              onClick={handlePrev}
            >
              {" "}
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />{" "}
            </span>
            <span
              // id="categories-next"
              className="carousel-control white-color mx-2"
              onClick={handleNext}
            >
              {" "}
              <FontAwesomeIcon icon={["fas", "chevron-right"]} />{" "}
            </span>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12 mb-0 mb-md-3 pb-0 pb-md-3">
            <OwlCarousel
              // id="categories-carousel"
              ref={owlCarouselRef}
              className="owl-theme"
              autoplay={true}
              nav={false}
              dots={false}
              loop={true}
              autoplayTimeout={2000}
              responsive={{
                0: {
                  items: 1,
                },
                768: {
                  items: 2,
                },
                992: {
                  items: 5,
                },
              }}
            >
              {categories.map((c, i) => (
                <CategoryBadge key={i + c.name} category={c} />
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  )
}
