import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Shop } from "../../models/base/shop.interface"
import { Review } from "../../models/base/review.interface"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { CouponWithShop } from "../../models/base/couponWithShop.interface"
import { ReactNode } from "react"
import { ReviewShopUnion } from "../../models/base/reviewShopUnion.interface"
import { EsaScraperShop } from "../../models/esaScraper/esaScraperShop.interface"

export interface ModalsState {
  couponModal: CouponWithShop | undefined
  esaValuesModal: Shop | undefined
  esaScoreModal: Shop | undefined
  infoModal: { title: string; body: ReactNode } | undefined
  productModal: EsaScraperProduct | undefined
  reportModal: ReviewShopUnion | undefined
  shareModal: Review | undefined
  unlockedCouponsModal: boolean
  loginModal: any | undefined
  registrationModal: any | undefined
  guestModal: any | undefined
  onExitModal: EsaScraperShop | undefined,
  updateReviewModal : any | undefined
}

const initialState: ModalsState = {
  couponModal: undefined,
  esaValuesModal: undefined,
  esaScoreModal: undefined,
  infoModal: undefined,
  productModal: undefined,
  shareModal: undefined,
  reportModal: undefined,
  unlockedCouponsModal: false,
  loginModal: undefined,
  registrationModal: undefined,
  guestModal: undefined,
  onExitModal: undefined,
  updateReviewModal: undefined,
}

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setEsaValuesModal: (state, action: PayloadAction<Shop | undefined>) => {
      state.esaValuesModal = action.payload
    },
    setEsaScoreModal: (state, action: PayloadAction<Shop | undefined>) => {
      state.esaScoreModal = action.payload
    },
    setCouponModal: (
      state,
      action: PayloadAction<CouponWithShop | undefined>,
    ) => {
      state.couponModal = action.payload
    },
    setInfoModal: (
      state,
      action: PayloadAction<{ title: string; body: ReactNode } | undefined>,
    ) => {
      state.infoModal = action.payload
    },
    setProductModal: (
      state,
      action: PayloadAction<EsaScraperProduct | undefined>,
    ) => {
      state.productModal = action.payload
    },
    setReportModal: (
      state,
      action: PayloadAction<ReviewShopUnion | undefined>,
    ) => {
      state.reportModal = action.payload
    },
    setShareModal: (state, action: PayloadAction<Review | undefined>) => {
      state.shareModal = action.payload
    },
    setUnlockedCouponsModal: (state, action: PayloadAction<boolean>) => {
      state.unlockedCouponsModal = action.payload
    },
    setLoginModal: (state, action: PayloadAction<any | undefined>) => {
      state.registrationModal = undefined
      state.guestModal = undefined
      state.loginModal = action.payload
    },
    setRegistrationModal: (state, action: PayloadAction<any | undefined>) => {
      state.loginModal = undefined
      state.guestModal = undefined
      state.registrationModal = action.payload
    },
    setGuestModal: (state, action: PayloadAction<any | undefined>) => {
      state.registrationModal = undefined
      state.loginModal = undefined
      state.guestModal = action.payload
    },
    setOnExitModal: (
      state,
      action: PayloadAction<EsaScraperShop | undefined>,
    ) => {
      state.onExitModal = action.payload
    },
    setUpdateReviewModal: (state, action: PayloadAction<any | undefined>) => {
      state.updateReviewModal = action.payload
    },
  },
})

export const {
  setEsaValuesModal,
  setEsaScoreModal,
  setCouponModal,
  setInfoModal,
  setProductModal,
  setReportModal,
  setShareModal,
  setUnlockedCouponsModal,
  setLoginModal,
  setRegistrationModal,
  setGuestModal,
  setOnExitModal,
  setUpdateReviewModal,
} = modalsSlice.actions

export const selectEsaValuesModal = (state: RootState) =>
  state.modals.esaValuesModal
export const selectEsaScoreModal = (state: RootState) =>
  state.modals.esaScoreModal
export const selectCouponModal = (state: RootState) => state.modals.couponModal
export const selectInfoModal = (state: RootState) => state.modals.infoModal
export const selectProductModal = (state: RootState) =>
  state.modals.productModal
export const selectReportModal = (state: RootState) => state.modals.reportModal
export const selectShareModal = (state: RootState) => state.modals.shareModal
export const selectUnlockedCouponsModal = (state: RootState) =>
  state.modals.unlockedCouponsModal
export const selectLoginModal = (state: RootState) => state.modals.loginModal
export const selectRegistrationModal = (state: RootState) =>
  state.modals.registrationModal
export const selectGuestModal = (state: RootState) => state.modals.guestModal
export const selectOnExitModal = (state: RootState) => state.modals.onExitModal
export const selectUpdateReviewModal = (state: RootState) => state.modals.updateReviewModal

export default modalsSlice.reducer
