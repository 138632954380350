import React from "react"
import { EsaScraperShop } from "../../models/esaScraper/esaScraperShop.interface"
import {
  selectMacroCategory,
  selectSubCategory,
} from "../../utils/categoryUtils"

interface ReviveBannerProps {
  zoneId: string
  shop?: EsaScraperShop
}

export const ReviveBanner: React.FC<ReviveBannerProps> = ({ zoneId, shop }) => {
  return (
    <ins
      data-revive-zoneid={zoneId}
      data-revive-id="29ea987e24f803f3a7e29ba68976b9fb"
      {...(selectSubCategory(shop?.esa_categories)?.slug
        ? {
            "data-revive-categoria": selectSubCategory(shop?.esa_categories)
              ?.slug,
          }
        : {})}
      {...(selectMacroCategory(shop?.esa_categories)?.slug
        ? {
            "data-revive-macro": selectMacroCategory(shop?.esa_categories)
              ?.slug,
          }
        : {})}
    ></ins>
  )
}
