import React from "react"
import { Link } from "react-router-dom"
import starOrange from "../../assets/icons/star_orange.webp"
import { EsaScraperShop } from "../../models/esaScraper/esaScraperShop.interface"
import { removeHtmlTags } from "../../utils/stringUtils"
import { LocalBusinessSchema } from "../schemas/LocalBusinessSchema"
import { useGetShopsQuery } from "../../app/services/base/shops"
import { skipToken } from "@reduxjs/toolkit/query"

interface ShopsCarouselItemProps {
  shop: EsaScraperShop
}

export const ShopsCarouselItem: React.FC<ShopsCarouselItemProps> = ({
  shop,
}) => {
  const { eShop, isError } = useGetShopsQuery(
    shop.domain ? { domain: shop.domain } : skipToken,
    {
      selectFromResult: ({ data, isError }) => ({
        eShop: data?.find(
          (shop) =>
            shop.domain == shop.domain ||
            shop.domain.replace("/", "*") == shop.domain,
        ),
        isError,
      }),
    },
  )

  debugger
  return (
    <div
      key={`carousel${shop.esa_id}`}
      className="item background-custom-gray custom-rounded p-3"
      style={{
        minHeight: "204px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {eShop ? (
        <LocalBusinessSchema
          shop={eShop}
          reviews={[]}
          hideReviews
          hideDataset
        />
      ) : null}
      <div>
        <div className="row d-flex align-items-center justify-content-between mb-3">
          <div className="col-8 col-sm-10 d-flex align-items-center line-clamp">
            <Link
              className="dark-color orange-hover font-16 fw-semibold mb-0 underline"
              to={`/eshop/${shop.domain}`}
            >
              {shop.domain}
            </Link>
          </div>
          <div className="col-4 col-sm-2 d-flex align-items-center justify-content-end">
            <img
              src={starOrange}
              className="height-15 width-auto"
              style={{ position: "relative", top: "-1px" }}
              alt="Punteggio recensioni"
              loading="lazy"
            />
            <span className="simple-orange-txt font-16 fw-semibold ms-1 me-2">
              {shop.score}
            </span>
          </div>
        </div>

        <p className="gray-text font-14 mb-4 line-clamp line-clamp-4">
          {removeHtmlTags(shop.description)}
        </p>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center me-3">
          <Link
            className="dark-color font-16 xs-font-14 orange-hover underline fs-16-14 fw-500 w-wrap"
            to={`/eshop/${shop.domain}`}
          >
            Vai alla vetrina
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <Link
            className="simple-orange-link font-16 xs-font-14 underline fs-16-14 fw-500 w-wrap"
            to={`/invia-recensione?e=${shop.esa_id}`}
          >
            Lascia una recensione
          </Link>
        </div>
      </div>
    </div>
  )
}
