import React from "react"
import { Helmet } from "react-helmet"
import { Shop } from "../../models/base/shop.interface"
import { SimpleUser } from "../../models/base/simpleUser.interface"
import { Review } from "../../models/base/review.interface"

interface ReviewSchemaProps {
  shop: Shop
  review: Review
  simpleUser: SimpleUser
}

export const ReviewSchema: React.FC<ReviewSchemaProps> = ({
  shop,
  review,
  simpleUser,
}) => {
  let author

  if (simpleUser) {
    if (simpleUser.name != "" || simpleUser.surname != "") {
      author = [
        {
          "@type": "Person",
          name: `${simpleUser.name + " " + simpleUser.surname}`,
          url: "$userUrl",
        },
      ]
    }
  } else {
    author = [
      {
        "@type": "Organization",
        name: "eShoppingAdvisor",
        url: `${import.meta.env.BASE_URL}eshop/${shop.domain}`,
      },
    ]
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
            "@context": "http://schema.org/",
            "@type": "Review",
            "reviewBody": "${review.description}",
            "author": ${JSON.stringify(author)},
            "itemReviewed":
            {
              "@type": "LocalBusiness",
              "name": "${shop.domain}",
              "image": "${shop.eshopData.url}",
              "address":
              {
                "@type": "PostalAddress",
                "streetAddress": "${
                  shop.eshopData.registration_address?.address
                }",
                "addressLocality": "${
                  shop.eshopData.registration_address?.city
                }",
                "addressCountry": "${
                  shop.eshopData.registration_address?.nation
                }"
              },
              "telephone": "${shop.eshopData.registration_address?.phone}"
            },
            "reviewRating":
            {
              "@type": "Rating",
              "ratingValue": ${review.overall_rating},
              "worstRating": 1,
              "bestRating": 5
            }
          }`}
      </script>
    </Helmet>
  )
}
