import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import {
  initFacebookSdk,
  getFacebookLoginStatus,
  fbLogin,
  getProfileData,
} from "../../utils/facebookSdk"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setUser } from "./authSlice"
import { addToast } from "../toasts/toastsSlice"
import {
  useCreateUserMutation,
  useLoginMutation,
} from "../../app/services/base/users"
import {
  selectLoginModal,
  selectRegistrationModal,
  setLoginModal,
  setRegistrationModal,
} from "../modals/modalsSlice"
import { useAppSelector } from "../../app/hooks"

interface FacebookAuthProps {
  type: "Login" | "Signup"
}

export const FacebookAuth: React.FC<FacebookAuthProps> = ({ type }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loginModal = useAppSelector(selectLoginModal)
  const registrationModal = useAppSelector(selectRegistrationModal)
  const location = useLocation()
  const from = location.state?.from?.pathname || "/"

  const [login, { error }] = useLoginMutation()
  const [createUser] = useCreateUserMutation()

  function handleFacebookLogin() {
    getFacebookLoginStatus()
      .then((response: any) => {
        if (response.status === "connected") {
          // User is already logged in
          return response
        } else {
          // First time log in with Facebook
          return fbLogin()
        }
      })
      .then((loginResponse: any) => {
        if (loginResponse.status === "connected") {
          // Successfully logged in, retrieve profile data
          return getProfileData()
        } else {
          // Facebook login failed
          throw new Error("Ops! Impossibile accedere con Facebook.")
        }
      })
      .then(async (profileResponse: any) => {
        if (type == "Signup") {
          const response = await createUser({
            email: profileResponse.email,
            user_id: profileResponse.id,
            type: "facebook",
            name: profileResponse.first_name,
            surname: profileResponse.last_name,
            image_url: profileResponse.picture.data.url,
          }).unwrap()
          if (!response.success) throw new Error()
          // navigate("/registrazione/ok")
        }
        // else if (type == "Login") {
        // Login

        const authResp = await login({
          email: profileResponse.email,
          type: "facebook",
          user_id: profileResponse.id,
        }).unwrap()
        dispatch(setUser(authResp))
        if (!registrationModal && !loginModal) {
          navigate(from, { replace: true })
        } else {
          dispatch(setLoginModal(undefined))
          dispatch(setRegistrationModal(undefined))
        }
        // }
      })
      .catch((error) => {
        dispatch(
          addToast({
            message:
              error.data && error.data.message
                ? error.data.message
                : "Ops! Impossibile accedere con Facebook.",
            type: "danger",
          }),
        )
      })
  }

  useEffect(() => {
    initFacebookSdk()
  }, [])

  return (
    <button
      onClick={handleFacebookLogin}
      className="btn btn-outline-gray d-flex align-items-center justify-content-center w-100 p-3 mb-3 mb-lg-0 me-1"
      style={{ height: "50px" }}
      type="button"
    >
      <FontAwesomeIcon icon={["fab", "facebook-f"]} className="font-24" />
    </button>
  )
}
