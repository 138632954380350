import React from "react"
import { Link } from "react-router-dom"
import ticketWhite from "../../assets/icons/ticket_white.webp"
import tagWhite from "../../assets/icons/tag_white.webp"
import { formatNumber, removeHtmlTags } from "../../utils/stringUtils"
import { EsaScraperShop } from "../../models/esaScraper/esaScraperShop.interface"
import shopPlaceholderLogo from "../../assets/images/thumb_placeholder.png"
import { getShopLogo } from "../../utils/shopUtils"
import { RatingStars } from "../rating_stars/RatingStars"
import { useGetCouponsQuery } from "../../app/services/base/coupons"

interface ShopCardProps {
  shop: EsaScraperShop
}

export const ShopCard: React.FC<ShopCardProps> = ({
  shop: { esa_id, domain, score, reviews, description, coupon },
}) => {
  // const { data: couponsWithShops } = useGetCouponsQuery({
  //   domain: domain,
  //   page: 1,
  //   perPage: 1,
  // })
  // const randomCouponWithShop =
  //     coupon
  //     ? coupon
  //     : undefined
  const infoCoupon = coupon
    ? `${formatNumber(Number(coupon?.discount), 0)}${
        coupon?.discount_type == 1 ? "%" : "€"
      }`
    : undefined
  return (
    <div className="card-eshop-result col-12 col-md-6 col-lg-3 margin-b24-16">
      <div className="item-result transparent-card border custom-rounded">
        {coupon && infoCoupon && (
          <figure className="badge background-gradient-orange w-fit card-item-discount font-14 m-0">
            <img
              src={coupon.discount_type == 1 ? ticketWhite : tagWhite}
              className="ticket me-1 img-fluid height-20 width-auto"
              alt={`${domain} - Coupon sconto da ${infoCoupon}`}
            />
            {infoCoupon}
          </figure>
        )}

        <div className="row mx-1">
          <figure className="col-5 col-md-12 text-center my-3 my-md-1 p-3 p-md-1">
            <Link to={`/eshop/${domain}`} className="waitLink">
              <img
                src={shopPlaceholderLogo}
                data-src={getShopLogo(esa_id)}
                className="card-img-top card-item-img box-shadow custom-rounded"
                alt={domain}
                loading="lazy"
                onError={(e) => {
                  e.currentTarget.src = shopPlaceholderLogo
                  e.currentTarget.dataset.src = ""
                }}
                onLoad={(e) => {
                  if (
                    e.currentTarget.dataset.src &&
                    e.currentTarget.src !== e.currentTarget.dataset.src
                  ) {
                    e.currentTarget.src = e.currentTarget.dataset.src
                  }
                }}
              />
            </Link>
          </figure>

          <div className="col-7 col-md-12 mt-3 mt-md-1 p-3 ps-1 p-md-1 text-start text-md-center">
            <div className="min-45-box line-clamp line-clamp-2">
              <Link
                to={`/eshop/${domain}`}
                className="d-block card-title dark-color font-bolder pe-3 pe-md-0 me-2 mx-md-2 font-16 text-decoration-none orange-hover waitLink"
              >
                {domain}
              </Link>
            </div>
            <Link
              to={`/eshop/${domain}`}
              className="d-block d-md-none text-decoration-none waitLink mt-2"
            >
              <RatingStars
                rating={score}
                showAverageRating={true}
                bigRating
                size="small"
                nReviews={reviews}
                customNReviewText="su"
                customNReviewClass="simple-orange-txt font-16 underline mt-2 d-block"
              />
            </Link>
          </div>

          <Link
            to={`/eshop/${domain}`}
            className="d-none d-md-block px-md-3 text-decoration-none waitLink"
          >
            <RatingStars
              rating={score}
              showAverageRating={true}
              bigRating
              size="normal-xs-small"
              nReviews={reviews}
              customNReviewText="su"
              customNReviewClass="simple-orange-txt font-16 underline"
            />
          </Link>

          <p className="gray-text font-14 my-3 mt-0 mt-md-3 px-4 px-md-3 line-clamp line-clamp-4 min-80-box">
            {removeHtmlTags(description)}
          </p>
        </div>

        <div className="card-footer">
          <div className="d-block mt-2">
            <Link
              to={`/invia-recensione?e=${esa_id}`}
              className="btn btn-outline-animation btn-outline-gradient-orange w-100 py-2 font-bolder waitLink"
            >
              Scrivi una recensione
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
