import React from "react"
import { Modal } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectLoginModal, setLoginModal } from "./modalsSlice"
import { LoginAuth } from "../auth/LoginAuth"
import { InvitationOrder } from "../../models/base/invitationOrder.interface"

interface LoginModalProps {
  invitationOrder?: InvitationOrder
}

export const LoginModal: React.FC<LoginModalProps> = ({ invitationOrder }) => {
  const login = useAppSelector(selectLoginModal)
  const dispatch = useAppDispatch()

  return (
    <>
      <Modal
        show={login ? true : false}
        onHide={() => dispatch(setLoginModal(undefined))}
        // size="lg"
        centered
        aria-labelledby="loginModalLabel"
        dialogClassName="justify-content-center custom-modal-max-width"
        contentClassName="bg-transparent border-0"
      >
        <div className="d-flex align-items-center justify-content-center d-none d-lg-flex mb-4">
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={() => dispatch(setLoginModal(undefined))}
          ></button>
        </div>
        <div
          className="card d-flex flex-column align-items-center justify-content-center p-3 p-lg-4"
          style={{ borderRadius: "20px" }}
        >
          <LoginAuth isInModal invitationOrder={invitationOrder} />
        </div>
        <div className="d-flex align-items-center justify-content-center d-lg-none mt-4">
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={() => dispatch(setLoginModal(undefined))}
          ></button>
        </div>
      </Modal>
    </>
  )
}
