import { Link } from "react-router-dom"
import { Category } from "../../../models/base/category.interface"
import { Accordion, Card, useAccordionButton } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import parse from "html-react-parser"

const CustomToggle = ({ children, eventKey, callback }: any) => {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )

  return (
    <button
      className="btn btn-link bolder-dark-title font-23 gray-hover collapsed"
      type="button"
      onClick={decoratedOnClick}
    >
      <FontAwesomeIcon icon={["fas", "chevron-down"]} />
    </button>
  )
}

interface CategoryHighlightProps {
  index: number
  category: Category
}

export const CategoryHighlight: React.FC<CategoryHighlightProps> = ({
  index,
  category: {
    imageUrl,
    slug,
    name,
    details: { html, category_cta, coupons_cta },
  },
}) => {
  return (
    <div
      // id="categoriesHighlights_0"
      className={`container ${index > 0 ? "mt-5" : ""}`}
    >
      <Accordion
        defaultActiveKey="0"
        bsPrefix=" "
        className="px-3 xs-transparent-background background-white radius-75"
      >
        <Card className="no-borders mb-3 mb-md-0">
          <Card.Header className="background-white no-borders radius-75">
            <h5 className="mb-0">
              <div className="row">
                <div className="col-12 col-md-11 my-3">
                  <Link
                    to={`/categorie/${slug}`}
                    // className="dark-color font-bolder font-23 me-4 cat-item orange-hover text-decoration-none waitLink"
                    className="font-bolder dark-color gray-hover cursor-pointer font-23 collapsed text-decoration-none"
                  >
                    {name}
                  </Link>
                </div>
                <div className="col-12 col-md-1 text-center mt-3 mt-md-0">
                  <CustomToggle eventKey={index.toString()}></CustomToggle>
                </div>
              </div>
            </h5>
          </Card.Header>
          <Accordion.Collapse eventKey={index.toString()}>
            <Card.Body>
              <div className="row align-items-center">
                <div className="col-12 col-lg-3 mb-3">
                  <Link to={`/categorie/${slug}`}>
                    <img
                      src={imageUrl}
                      className="img-fluid custom-rounded height-215"
                    />
                  </Link>
                </div>
                <div className="col-12 col-lg-9 gray-color mt-xs-3 text-center text-lg-start xs-font-16 font-18 mb-3">
                  {parse(html)}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-4 text-center mt-3">
                  <Link
                    to={`/categorie/${slug}`}
                    className="btn btn-custom-white w-100 py-2 underline"
                    title={`Home Categoria ${name}`}
                  >
                    {category_cta}
                  </Link>
                </div>
                <div className="col-12 col-md-4 text-center mt-3">
                  <Link
                    to={`/coupon/${slug}`}
                    className="btn btn-custom-white w-100 py-2 underline"
                    title={`Home Categoria ${name}`}
                  >
                    {coupons_cta}
                  </Link>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}
