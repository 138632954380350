import { Link } from "react-router-dom"
import { Coupon } from "../../models/base/coupon.interface"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { setCouponModal } from "../modals/modalsSlice"
import { selectCurrentUser } from "../auth/authSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CouponWithShop } from "../../models/base/couponWithShop.interface"
import {
  formatNumber,
  toStringFromDateTimeWithSlash,
} from "../../utils/stringUtils"
import thumbPlaceholder from "../../assets/images/thumb_placeholder.webp"
import { useUnlockCouponMutation } from "../../app/services/base/coupons"
import { addToast } from "../toasts/toastsSlice"
import { useState } from "react"
import { RatingStars } from "../rating_stars/RatingStars"

interface CouponTicketProps {
  couponWithShop: CouponWithShop
  itemCustomClass?: string //"coupon-border-bg-white"
  removeLinkVetrina?: boolean
  onlyInfo?: boolean
  isUnlocked?: boolean
}

export const CouponTicket: React.FC<CouponTicketProps> = ({
  couponWithShop,
  itemCustomClass,
  removeLinkVetrina,
  onlyInfo,
  isUnlocked,
}) => {
  const user = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [unlockCoupon, { error }] = useUnlockCouponMutation()

  const cdnBaseUrl = import.meta.env.VITE_CDN_BASE_URL
  // const couponImg = couponWithShop.coupon.img
  //   ? "/coupons/" + couponWithShop.coupon.img
  //   : "/logos/" + couponWithShop.eshop.id + ".webp"
  const couponImg = "/logos/" + couponWithShop.eshop.id + ".webp"

  async function handleUnlockCoupon(coupon: Coupon) {
    try {
      const response = user
        ? await unlockCoupon({
            userId: user.id,
            couponId: coupon.id,
          }).unwrap()
        : false

      if (response) {
        dispatch(
          addToast({
            message: "Coupon sbloccato!",
            type: "success",
          }),
        )
      } else {
        dispatch(
          addToast({
            message: "Ops! Non hai abbastanza punti per sbloccare il coupon.",
            type: "danger",
          }),
        )
      }
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Qualcosa è andato storto.",
          type: "danger",
        }),
      )
    }
  }

  return (
    <li
      className={`p-4 my-4 coupon-border ${
        itemCustomClass ? itemCustomClass : ""
      }`}
    >
      <div className="px-2">
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
          <div className="mb-3 mb-lg-0 me-lg-3">
            <div className="coupon-details-photo d-flex align-items-center">
              {removeLinkVetrina ? (
                <img
                  src={cdnBaseUrl + couponImg}
                  className="w-100"
                  onError={(e) => (e.currentTarget.src = thumbPlaceholder)}
                />
              ) : (
                <Link
                  to={`/eshop/${couponWithShop.eshop.domain}`}
                  target="_blank"
                  className="text-decoration-none"
                >
                  <img
                    src={cdnBaseUrl + couponImg}
                    className="w-100"
                    onError={(e) => (e.currentTarget.src = thumbPlaceholder)}
                  />
                </Link>
              )}
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between w-100">
            <div className="d-flex flex-column align-items-center align-items-lg-start justify-content-center">
              {removeLinkVetrina ? (
                <p
                  className="font-16 font-bolder m-0"
                  style={{ color: "#e55e0e" }}
                >
                  {couponWithShop.eshop.domain}
                </p>
              ) : (
                <Link
                  to={`/eshop/${couponWithShop.eshop.domain}/`}
                  target="_blank"
                  className="text-decoration-none"
                >
                  <p
                    className="font-16 font-bolder m-0"
                    style={{ color: "#e55e0e" }}
                  >
                    {couponWithShop.eshop.domain}
                  </p>
                </Link>
              )}
              <h6 className="font-33 font-bolder dark-color m-0">
                Sconto {formatNumber(Number(couponWithShop.coupon.discount), 0)}
                {couponWithShop.coupon.discount_type === 1 ? "%" : "€"}
              </h6>
              <RatingStars
                rating={couponWithShop.eshop.rating}
                showAverageRating
                size="small"
              />
              <p className="lightgray-txt text-uppercase m-0 mb-3 mb-lg-0 line-clamp">
                {
                  //couponWithShop.categories.find((c) => c.parent === null)
                  couponWithShop.categories[0]?.description
                }
              </p>
            </div>
            {!onlyInfo && (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <p className="lightgray-txt m-0 mt-4 mb-3 mt-lg-0">
                  Scade il{" "}
                  {toStringFromDateTimeWithSlash(
                    couponWithShop.coupon.expire_date,
                  )}
                </p>
                <div
                  className={`c-coupon-codice border p-3 mb-3 ${
                    isUnlocked ? "" : "d-none"
                  }`}
                >
                  <span
                    className="c-label-detail cursor-pointer copy-trigger"
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(
                          couponWithShop?.coupon.code,
                        )
                        setIsCopied(true)
                        dispatch(
                          addToast({
                            message: "Testo copiato!",
                            type: "success",
                          }),
                        )
                      } catch (err) {
                        dispatch(
                          addToast({
                            message: "Ops! Impossibile copiare il testo.",
                            type: "danger",
                          }),
                        )
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={["fas", "copy"]} className="me-2" />{" "}
                    Codice:
                  </span>
                  <span className="c-val-detail">
                    {" "}
                    {isUnlocked ? couponWithShop.coupon.code : ""}{" "}
                  </span>
                  {isCopied && (
                    <span className="gray-text font-14 text-center">
                      <FontAwesomeIcon icon={["fas", "check"]} /> Copiato
                    </span>
                  )}
                </div>
                {!isUnlocked && (
                  <a
                    className="btn btn-gradient-orange py-2 d-flex align-items-center justify-content-center w-100 mb-3"
                    style={{ height: "50px" }}
                    onClick={() => {
                      if (user) {
                        handleUnlockCoupon(couponWithShop.coupon)
                      } else {
                        dispatch(setCouponModal(couponWithShop))
                      }
                    }}
                  >
                    <span className="d-none d-lg-flex">
                      Sblocca sconto con {couponWithShop.coupon.points_cost}{" "}
                      {couponWithShop.coupon.points_cost == 1
                        ? "punto"
                        : "punti"}
                    </span>
                    <span className="d-flex d-lg-none"> Sblocca sconto </span>
                  </a>
                )}
                <a
                  className="coupon-modal-button simple-blue-link font-12 m-0"
                  onClick={() => dispatch(setCouponModal(couponWithShop))}
                >
                  Condizioni e modalità di utilizzo
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  )
}
