import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { InputRatingStars } from "./InputRatingStars"

interface ReviewFieldInputProps {
  index: number
  label?: string
  infoText: string
  inputName: string
  reviewType: "shop" | "product"
  forceNotShowError?: boolean
}

export const ReviewFieldInput: React.FC<ReviewFieldInputProps> = ({
  index,
  label,
  infoText,
  inputName,
  reviewType,
  forceNotShowError,
}) => {
  return (
    <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between w-100 my-3">
      {label && (
        <div className="lightgray-txt font-16 font-semi-bolder mb-3 mb-lg-0">
          {index}. {label}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body>{infoText}</Popover.Body>
              </Popover>
            }
          >
            <span
              className="d-none d-lg-inline ms-2 badge badge-gray font-12"
              style={{
                padding: "2px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              <FontAwesomeIcon icon={["fas", "info"]} />
            </span>
          </OverlayTrigger>
        </div>
      )}
      <InputRatingStars
        inputName={inputName}
        reviewType={reviewType}
        customStarClass={
          reviewType === "shop"
            ? "lg-star-small-container"
            : "star-big-container"
        }
        customAverageRatingClass={reviewType === "product" ? "xs-font-16" : ""}
        forceNotShowError={forceNotShowError}
      />
    </div>
  )
}
