import React, { useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { InvitationOrder, InvitationOrderProduct } from "../../../models/base/invitationOrder.interface"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap"
import imagePlaceholder from "../../../assets/images/thumb_placeholder.webp"
import { MD5 } from "crypto-js"
import { Review } from "../../../models/base/review.interface"
import { useGetInvitationCodeQuery } from "../../../app/services/base/invitations"

interface InvitationOrderProps {
  invitationOrder: InvitationOrder
}

export const InvitationOrderItem: React.FC<InvitationOrderProps> = ({
  invitationOrder,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  
  
  const o = searchParams.get("o")
  const [open, setOpen] = useState<boolean>(
    o && o == invitationOrder.id.toString() ? true : false,
  )

  const productReviewEnabled =
    invitationOrder.products &&
    invitationOrder.domain_info.prod_review_enabled &&
    invitationOrder.domain_info.plan == "company"
      ? true
      : false

  const isProductReviewed = (
    product: InvitationOrderProduct,
    productReviews: Review[],
  ) => {
    //if product ids inside productReviews array with key product_id
    if (productReviews) {
      return (
        productReviews.find((review) => review.id_prod === product.id) !==
        undefined
      )
    }
    return false
  }

  const getProductReviewId = (
    product: InvitationOrderProduct,
    productReviews: Review[],
  ) => {
    if (productReviews) {
      const review = productReviews.find(
        (review) => review.id_prod === product.id,
      )
      return review?.id
    }
    return null
  }

  const totalProducts = invitationOrder.products?.length || 0

  let productColor

  if (
    invitationOrder.product_reviews &&
    invitationOrder.product_reviews?.length === totalProducts
  ) {
    productColor = "green"
  } else if (
    invitationOrder.product_reviews &&
    invitationOrder.product_reviews?.length > 0 &&
    invitationOrder.product_reviews?.length < totalProducts
  ) {
    productColor = "#EB6717"
  } else {
    productColor = "red"
  }

  const reviewCodeObj  = useGetInvitationCodeQuery(
      {
          eshop_id: invitationOrder.eshop_id.toString(),
          order_id: invitationOrder.id.toString(),
        }
  )
  const reviewCode = reviewCodeObj.data?.code

  return (
    <>
      <div className="row my-3 py-3 px-0 align-items-center reviewAcquistoItem text-center text-lg-left text-xl-left">
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 my-2 my-lg-0 my-xl-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                Ordine effettuato il <b>{invitationOrder.order_date}</b>{" "}
                {productReviewEnabled && (
                  <>
                    Prodotti recensiti
                    <b>
                      {" "}
                      <span style={{ color: productColor }}>
                        {invitationOrder.product_reviews?.length}
                      </span>{" "}
                      / {invitationOrder.products?.length}
                    </b>
                  </>
                )}
              </div>
              <div className="col-12">
                <Link
                  to={`/eshop/${invitationOrder.domain}`}
                  className="txt-blue-bold"
                  target="_blank"
                >
                  {invitationOrder.domain}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 my-2 mb-4 my-lg-0 my-xl-0">
          <div className="container" style={{ position: "relative" }}>
            <div className="row text-center">
              {invitationOrder.status == 1 ? (
                <>
                  {invitationOrder.use_date && (
                    <div className="col-12">
                      Recensito il {invitationOrder.use_date}
                    </div>
                  )}
                  {invitationOrder.use_date && (
                    <div className="col-12">
                      {invitationOrder.review && (
                        <Link
                          to={`/areariservata/reviews-modify/${invitationOrder.review?.id}?ac=view`}
                          className="txt-blue-bold underline"
                          target="_blank"
                        >
                          Vedi la recensione
                        </Link>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="col-12">
                  <Link
                    to={`/invia-recensione?e=${invitationOrder.eshop_id}&v=${invitationOrder.id}/${reviewCode}`}
                    className="btn btn-blue btn-fullscreen-mobile px-4"
                    target="_blank"
                  >
                    Recensisci
                  </Link>
                </div>
              )}
            </div>
            {productReviewEnabled && (
              <span
                className="float-right reviewAcquistoCollapseBtn"
                onClick={() => setOpen(!open)}
                aria-controls={`collapse_${invitationOrder.id}`}
                aria-expanded={open}
              >
                {open ? (
                  <FontAwesomeIcon icon={["fas", "caret-up"]} />
                ) : (
                  <FontAwesomeIcon icon={["fas", "caret-down"]} />
                )}
              </span>
            )}
          </div>
        </div>
      </div>

      {productReviewEnabled && (
        <Collapse in={open}>
          <div
            className="reviewAcquistoProdotto"
            id={`collapse_${invitationOrder.id}`}
          >
            {invitationOrder.products &&
              invitationOrder.products.length > 0 &&
              invitationOrder.products.map((product, index) => (
                <div key={product.id} className="row p-0 align-items-center">
                  {product.img_prod && (
                    <div className="my-3 my-lg-0 my-xl-0 col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center text-lg-left text-xl-left">
                      <img
                        src={product.img_prod}
                        className="img-fluid"
                        height="72"
                        width="72"
                        loading="lazy"
                        alt={product.name}
                        onError={(e) =>
                          (e.currentTarget.src = imagePlaceholder)
                        }
                      />
                    </div>
                  )}
                  <div
                    className={`my-3 my-lg-0 my-xl-0 ${
                      !product.img_prod
                        ? "col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8"
                        : "col-8 col-xs-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 pl-0"
                    }`}
                  >
                    <div className="row">
                      <div className="col-12">
                        Ordine effettuato il {invitationOrder.order_date}
                      </div>
                      <div className="col-12">
                        <span className="item-prod-name"> {product.name} </span>
                      </div>
                      <div className="col-12">
                        <Link
                          to={`/eshop/${invitationOrder.domain}`}
                          className="txt-blue-bold"
                          target="_blank"
                        >
                          {" "}
                          {invitationOrder.domain}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 my-3 my-lg-0 my-xl-0 text-center text-lg-right text-xl-right">
                    {isProductReviewed(
                      product,
                      invitationOrder.product_reviews || [],
                    ) ? (
                      <div className="row">
                        <div className="col-12">
                          Recensito il {product.review_date}
                        </div>
                        <div className="col-12">
                          <Link
                            to={`/areariservata/product-reviews-modify/${getProductReviewId(product, invitationOrder.product_reviews || [])}?ac=view`}
                            className="txt-orange-bold underline"
                            target="_blank"
                          >
                            Vedi la recensione
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <>
                        {invitationOrder.status != 1 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={invitationOrder.id.toString()}>
                                Recensisci prima il venditore!
                              </Tooltip>
                              // <div className='tooltip custom-tooltip' role='tooltip'><div className='arrow'></div><div className='tooltip-inner'></div></div>
                            }
                          >
                            <FontAwesomeIcon icon={["fas", "info-circle"]} />
                          </OverlayTrigger>
                        ) : (
                          <>
                            {invitationOrder.status == 1 && (
                              <Link
                                to={`/areariservata/recensione-prodotto/${invitationOrder.id}/${index}/${product.id}`}
                                className={`btn btn-outline-orange px-4 px-md-3 px-lg-4 px-xl-4 btn-fullscreen-mobile ${
                                  invitationOrder.status == 1 ? "" : "disabled"
                                }`}
                              >
                                Recensisci articolo
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="border-bottom-1 my-4"></div>
                  </div>
                </div>
              ))}
          </div>
        </Collapse>
      )}
    </>
  )
}

export default InvitationOrderItem
