import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectShareModal, setShareModal } from "./modalsSlice"
import { Modal } from "react-bootstrap"
import copyIcon from "../../assets/icons/copy_icon.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { addToast } from "../toasts/toastsSlice"

export const ShareModal: React.FC = () => {
  const review = useAppSelector(selectShareModal)
  const dispatch = useAppDispatch()
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const shareLink = encodeURI(
    `${import.meta.env.VITE_B2C_BASE_URL}it/reviews/${review?.id}/`,
  )

  return (
    <Modal
      show={review ? true : false}
      onHide={() => dispatch(setShareModal(undefined))}
      size="lg"
      centered
      aria-labelledby="shareModalLabel"
      dialogClassName="justify-content-center"
      contentClassName="bg-transparent position-relative border-0"
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="card p-3 px-lg-5">
          <div className="d-flex flex-column align-items-center justify-content-center border-0">
            <h5 className="font-33 font-bolder dark-color text-center my-2">
              Condividi recensione
            </h5>
            <p className="lightgray-txt text-center font-16 my-2 mb-lg-4 px-2 px-lg-4">
              Condividi la recensione tramite social network o copia il link per
              condividerlo ovunque tu voglia
            </p>
            <button
              type="button"
              className="btn-close btn-close-absolute d-none d-lg-block position-absolute p-0"
              onClick={() => dispatch(setShareModal(undefined))}
            ></button>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-0 mb-0 mb-lg-2 px-lg-5">
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between justify-content-lg-center modal-body-share">
              {[
                {
                  name: "LinkedIn",
                  url: "https://www.linkedin.com/sharing/share-offsite?mini=true&url=",
                  iconName: "linkedin" as IconName,
                },
                {
                  name: "Facebook",
                  url: "http://www.facebook.com/sharer.php?u=",
                  iconName: "facebook" as IconName,
                },
                {
                  name: "Twitter",
                  url: "http://twitter.com/intent/tweet?url=",
                  iconName: "twitter" as IconName,
                },
              ].map((social) => (
                <div
                  key={social.name}
                  className="d-flex flex-column align-items-center justify-content-center gap-3 mt-4 order-1 order-lg-2"
                >
                  <a
                    className="text-decoration-none cursor-pointer"
                    rel="nofollow"
                    onClick={(e) => {
                      window.open(
                        `${social.url}${shareLink}`,
                        "_blank",
                        "width=600,height=400",
                      )
                      return false
                    }}
                  >
                    <div
                      className="d-flex align-items-center justify-content-center rounded-circle text-center p-3 mx-auto"
                      style={{
                        width: "70px",
                        height: "70px",
                        border: "2px solid #a9a9a9",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fab", social.iconName]}
                        className="font-38 lightgray-txt"
                      />
                    </div>
                    <p className="d-lg-none lightgray-txt font-16">
                      Condividi con {social.name}
                    </p>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center border-0 p-0 mt-4 mt-lg-5 mb-3 px-lg-5">
            <div
              className="input-group search-bar-gray m-0"
              style={{ height: "60px" }}
            >
              <input
                type="text"
                className="form-control bg-white lightgray-txt font-semi-bolder font-16"
                value={shareLink}
                disabled={true}
              />
              <span className="d-none"> {shareLink} </span>
              <button
                className="input-group-text copy-trigger"
                style={{ background: "#a9a9a9" }}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(shareLink)
                    setIsCopied(true)
                    dispatch(
                      addToast({
                        message: "Link copiato!",
                        type: "success",
                      }),
                    )
                  } catch (err) {
                    dispatch(
                      addToast({
                        message: "Ops! Impossibile copiare il link.",
                        type: "danger",
                      }),
                    )
                  }
                }}
              >
                <img src={copyIcon} alt="Copia link" />
              </button>
            </div>
            {isCopied && (
              <span className="gray-text font-14">
                <FontAwesomeIcon icon={["fas", "check"]} /> Copiato
              </span>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center d-lg-none my-4">
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={() => dispatch(setShareModal(undefined))}
          ></button>
        </div>
      </div>
    </Modal>
  )
}
