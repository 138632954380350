import React from "react"
import starBig from "../../../../assets/icons/star_big.webp"
import tagWhite from "../../../../assets/icons/tag_white.webp"
import ticketWhite from "../../../../assets/icons/ticket_white.webp"
import { Link, useNavigate } from "react-router-dom"
import { HomeCoupon } from "../../../../models/base/homeCoupon.interface"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { selectCurrentUser } from "../../../../features/auth/authSlice"
import { couponsApi } from "../../../../app/services/base/coupons"
import { setCouponModal } from "../../../../features/modals/modalsSlice"

interface OwlCouponProps {
  coupon: HomeCoupon
}

export const OwlCoupon: React.FC<OwlCouponProps> = ({ coupon }) => {
  const user = useAppSelector(selectCurrentUser)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  async function handleClick() {
    if (user) {
      navigate(`/coupon/ultimi-coupon?coupon=${coupon.eshop_id}`)
    } else {
      const { data: couponWithShop } = await dispatch(
        couponsApi.endpoints.getCoupon.initiate(coupon.coupon_id),
      )
      dispatch(setCouponModal(couponWithShop))
    }
  }
  return (
    <div
      className="coupon-highlights-background card my-0 my-lg-5 no-border-radius background-no-repeat background-image-fit"
      style={{
        background: `url(${coupon.category_img})`,
      }}
      data-tm="TM_home_sponsoredshop_view_coupon_title"
    >
      <div className="containerDarkBackdrop min-h-550 w-100 h-100 py-3 px-0">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center py-2">
              <img
                src={starBig}
                className="width-20 d-inline-flex img-fluid"
                alt="Punteggio recensioni"
              />
              <span className="bolder-white-title text-overflow-ellipis font-23 d-inline-flex align-items-baseline ms-1">
                {coupon.eshop_rating.rating}{" "}
                <span className="fade-white-txt ms-2 font-16"> /5 </span>
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center pb-2 px-2">
              <Link
                to={`/eshop/${coupon.eshop_domain})`}
                className="simple-white-link bolder-white-title line-clamp line-clamp-1 font-33"
              >
                {coupon.eshop_domain}{" "}
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center px-1">
              <span className="simple-white-txt line-clamp line-clamp-2 font-14">
                Approfitta degli sconti!{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="row position-bottom position-center">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center pb-2 px-1">
              <img
                src={coupon.coupon_tipo_sconto == "1" ? ticketWhite : tagWhite}
                className="height-25 width-auto me-2"
                height="25px"
                width="20px"
                alt="ticket"
              />
              <span className="bolder-white-title line-clamp line-clamp-1 font-23">
                {parseInt(coupon.coupon_sconto, 10)}{" "}
                {coupon.coupon_tipo_sconto == "1" ? "%" : "€"}{" "}
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center pb-2 px-1">
              <a
                // to={`/eshop/${coupon.eshop_domain}#codici-sconto)`}
                onClick={handleClick}
                className="btn btn-gradient-orange w-100 my-3 py-3 font-bolder"
                title={coupon.eshop_domain}
              >
                Attiva
              </a>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center pb-3 px-1">
              <Link
                to="/coupon/ultimi-coupon"
                className="btn btn-outline-animation btn-outline-gradient-orange w-100 py-3 font-bolder waitLink"
              >
                Vai al catalogo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
