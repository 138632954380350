import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import { Link } from "react-router-dom"
import { Review } from "../../../models/base/review.interface"
import acquistiIcon from "../../../assets/icons/acquisti-icon.png"
import updateIcon from "../../../assets/icons/update.svg"
import { Shop } from "../../../models/base/shop.interface"

interface ReviewItemProps {
  review: Review
  shop: Shop
}

interface StatusInfo {
  statusText: string
  badgeColor: string
}

export const ReviewListItem: React.FC<ReviewItemProps> = ({ review, shop }) => {
  const popover = (
    <Popover className="z-index-1">
      <Popover.Body className="white-background">
        <div className="d-block">
          {review.order_id && (
            <Link
              className="d-block p-1 review-action-link review-inspect-link"
              to={`/areariservata/reviews-acquisti?o=${review.order_id}`}
            >
              <img
                src={acquistiIcon}
                className="img-fluid"
                loading="lazy"
                width="20px"
                height="18px"
              />
              <span className="ms-2"> Vedi Ordine </span>
            </Link>
          )}

          <Link
            className="d-block my-2 p-1 review-action-link review-inspect-link"
            to={`/areariservata/reviews-modify/${review.id}?ac=view`}
          >
            <img
              src={updateIcon}
              className="img-fluid"
              loading="lazy"
              width="20px"
              height="18px"
            />
            <span className="ms-2"> Gestione recensione </span>
          </Link>
        </div>
      </Popover.Body>
    </Popover>
  )

  const getStatusInfo = (status: number): StatusInfo => {
    const statusMap: Record<number, StatusInfo> = {
      0: { statusText: "In attesa di validazione", badgeColor: "warning" },
      1: { statusText: "Pubblicata", badgeColor: "success" },
      2: { statusText: "Non Validata", badgeColor: "danger" },
      3: { statusText: "In attesa di pubblicazione", badgeColor: "warning" },
    }

    return statusMap[status] || { statusText: "", badgeColor: "" }
  }

  const { statusText, badgeColor } = getStatusInfo(review.status)

  return (
    <tr className="first">
      <td className="insert-date">
        <span className="data">{review.insert_date}</span>
      </td>
      <td className="eshop-name">
        <Link to={`/areariservata/reviews-modify/${review.id}?ac=view`}>
          {shop.domain}
        </Link>
      </td>
      <td className="title">
        {review.is_prod_review == 1 && (
          <span style={{ marginRight: "5px", color: "#fe2131" }}>P</span>
        )}
        {review.title}
      </td>
      <td className="text-center">
        <a title={statusText}>
          <span className={`badge badge-small badge-${badgeColor}`}> </span>
        </a>
      </td>
      <td className="text-center">
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
          <span className="cursor-pointer">
            <FontAwesomeIcon
              icon={["fas", "ellipsis-vertical"]}
              className="dark-color"
            />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  )
}

export default ReviewListItem
