import React, { useState } from "react"
import { Tab } from "react-bootstrap"
import { Shop } from "../../../../models/base/shop.interface"
import { Link } from "react-router-dom"
import {
  useGetCouponsQuery,
  useGetCouponsUserHistoryQuery,
} from "../../../../app/services/base/coupons"
import { AppPagination } from "../../../app_pagination/AppPagination"
import { CouponTicket } from "../../../coupon_ticket/CouponTicket"
import { selectCurrentUser } from "../../../auth/authSlice"
import { useAppSelector } from "../../../../app/hooks"
import { skipToken } from "@reduxjs/toolkit/query"

interface CouponsTabContentProps {
  shop: Shop
}

export const CouponsTabContent: React.FC<CouponsTabContentProps> = ({
  shop,
}) => {
  const user = useAppSelector(selectCurrentUser)
  const [page, setPage] = useState<number>(1)

  const { data: couponsWithShops } = useGetCouponsQuery({
    eshop_id: shop.id,
    page,
    perPage: 10,
  })

  const { data: unlockedCoupons } = useGetCouponsUserHistoryQuery(
    user
      ? {
          userId: user.id,
        }
      : skipToken,
  )

  function handlePageChange(page: number) {
    setPage(page)
  }

  return (
    <Tab.Pane eventKey="coupons" aria-labelledby="Coupon Sconto">
      <p className="font-16 font-bolder dark-color m-0 mb-2 mt-1 mt-lg-4">
        Risparmia grazie ai Codici sconto offerti da {shop.domain}
      </p>
      <p className="m-0">
        Per utilizzare il coupon accedi al sito del negozio e segui le
        condizioni e le modalità di utilizzo indicate.{" "}
        <Link
          to="/info/coupon-info"
          className="simple-blue-link"
          target="_blank"
        >
          Scopri come guadagnare i punti per sbloccare i coupon!
        </Link>
      </p>
      <ul className="list-unstyled p-0">
        <div className="col-12">
          {couponsWithShops?.items.map((sCoupon) => (
            <CouponTicket
              key={sCoupon.coupon.id}
              couponWithShop={sCoupon}
              isUnlocked={
                unlockedCoupons
                  ? unlockedCoupons?.purchases.some(
                      (purchase) => purchase.id == sCoupon.coupon.id,
                    )
                  : false
              }
              onlyInfo={false}
              removeLinkVetrina={false}
            />
          ))}
        </div>
      </ul>
      {couponsWithShops &&
        Number(couponsWithShops?.pagination.total_count) > 10 && (
          <AppPagination
            currentPage={page}
            totalPages={Number(couponsWithShops?.pagination.page_count)}
            handlePageChange={handlePageChange}
          />
        )}
    </Tab.Pane>
  )
}
