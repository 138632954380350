export const toRatingText = (rating: number): string => {
  if (rating < 1) {
    return ""
  } else if (rating >= 1 && rating <= 1.7) {
    return "Pessimo"
  } else if (rating > 1.7 && rating <= 2.7) {
    return "Scarso"
  } else if (rating > 2.7 && rating <= 3.7) {
    return "Nella media"
  } else if (rating > 3.7 && rating <= 4.2) {
    return "Molto buono"
  } else if (rating > 4.2 && rating <= 4.7) {
    return "Ottimo"
  } else {
    return "Eccellente"
  }
}

export const monthTranslation = (month: string) => {
  switch (month) {
    case "January":
      return "Gennaio"
    case "February":
      return "Febbraio"
    case "March":
      return "Marzo"
    case "April":
      return "Aprile"
    case "May":
      return "Maggio"
    case "June":
      return "Giugno"
    case "July":
      return "Luglio"
    case "August":
      return "Agosto"
    case "September":
      return "Settembre"
    case "October":
      return "Ottobre"
    case "November":
      return "Novembre"
    case "December":
      return "Dicembre"
    default:
      return ""
  }
}

export const formatNumber = (number: number, decimalPlaces: number) => {
  if(number !== null && number !== undefined && !isNaN(number)){
    return number.toLocaleString("en-US", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  }
  else{
    return "0.0"
  }
}

// Format of date string: yyyy-MM-dd hh:mm:ss
export const toStringFromDateTime = (dateString: string): string => {
  const dateObject: Date = new Date(dateString)

  const day: string = String(dateObject.getDate()).padStart(2, "0")
  const month: string = String(dateObject.getMonth() + 1).padStart(2, "0")
  const year: number = dateObject.getFullYear()

  const formattedDate: string = `${day}-${month}-${year}`

  return formattedDate
}

// Format of date string: yyyy-MM-dd hh:mm:ss
export const toStringFromDateTimeWithSlash = (dateString: string): string => {
  const dateObject: Date = new Date(dateString)

  const day: string = String(dateObject.getDate()).padStart(2, "0")
  const month: string = String(dateObject.getMonth() + 1).padStart(2, "0")
  const year: number = dateObject.getFullYear()

  const formattedDate: string = `${day}/${month}/${year}`

  return formattedDate
}

export const formatDateWithExtensedMonth = (dateString: string): string => {
  const dateObject = new Date(dateString)

  const browserLanguage = navigator.language
  const monthNames = new Intl.DateTimeFormat(browserLanguage, {
    month: "long",
  }).formatToParts(dateObject)

  const day = dateObject.getDate()
  const month = monthNames.find((part) => part.type === "month")?.value
  const capitalizedMonth = month
    ? month.charAt(0).toUpperCase() + month.slice(1)
    : undefined
  //const translatedMonth = monthTranslation(capitalizedMonth || "")
  const year = dateObject.getFullYear()

  const formattedDate = `${day} ${capitalizedMonth} ${year}`

  return formattedDate
}

export const removeHtmlTags = (htmlText: string) => {
  // return htmlText.replace(/<[^>]*>/g, " ")
  let doc = new DOMParser().parseFromString(htmlText, "text/html")
  return doc.body.textContent || ""
}

export const findSubstringParts = (substring: string, fullString: string) => {
  if (substring && fullString) {
    fullString = fullString.replace(/^"(.*)"$/, "$1")

    const index = fullString.toLowerCase().indexOf(substring.toLowerCase())

    if (index !== -1) {
      const containedPart = fullString.substring(
        index,
        index + substring.length,
      )
      const notContainedPart =
        fullString.substring(0, index) +
        fullString.substring(index + substring.length)

      return [containedPart, notContainedPart]
    } else {
      return ["", fullString]
    }
  }
  return ""
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const capitalizeEveryWord = (str: string) => {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase()
  })
}

export const generateDatesFromMonthString = (monthString: string) => {
  // Parsing della stringa del mese
  const [year, month] = monthString.split("-").map(Number)

  // Creazione della prima data con il primo giorno del mese
  const firstDayOfMonth = new Date(year, month - 1, 1)
  const formattedFirstDay = formatDate(firstDayOfMonth)

  // Creazione della seconda data con l'ultimo giorno del mese
  const lastDayOfMonth = new Date(year, month, 0)
  const formattedLastDay = formatDate(lastDayOfMonth)

  return [formattedFirstDay, formattedLastDay]
}

export const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

export const formatToYearMonth = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  return `${year}-${month}`
}
