import React from "react"
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { Category } from "../../../models/base/category.interface"

interface CategoryBadgeProps {
  category: Category
}

export const CategoryBadge: React.FC<CategoryBadgeProps> = ({ category }) => {
  return (
    <div className="item py-3 pe-3">
      <Link
        to={`/categorie/${category.slug}`}
        className="badge badge-white simple-link d-inline-block py-3 w-100 text-overflow-ellipis"
      >
        <FontAwesomeIcon icon={findIconDefinition(category.icon)} />{" "}
        {category.details.title ? category.details.title : category.name}
      </Link>
    </div>
  )
}
