import React from "react"
import "./disclaimer.css"
import { Link } from "react-router-dom"

export const Disclaimer: React.FC = () => {
  return (
    <section className="condizioni-page">
      <div className="container-fluid">
        <div className="row">
          <div className="condizioni-head">
            <div className="condizioni-head-title">
              <h1>Disclaimer</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col col-sm col-md col-lg col-xl">
            <div className="condizioni-content">
              <p>&nbsp;</p>
              <p>
                <em>
                  World Advisor&nbsp;Srl non altera e non esercita alcun
                  controllo sulle recensioni pubblicate e, pertanto, declina
                  ogni responsabilità in merito. Ciononostante&nbsp;
                  <em>World Advisor&nbsp;Srl</em> si riserva la facoltà di
                  bloccare qualsiasi recensione non conforme ai termini di
                  utilizzo, senza preavviso. <em>World Advisor&nbsp;Srl</em>, in
                  buona fede, se i contenuti pubblicati violano i termini di
                  utilizzo, potrà procedere alla rimozione per tutelare i propri
                  diritti e quelli di altri utenti.
                </em>
              </p>
              <p>
                <em>
                  Attraverso l’indirizzo email{" "}
                  <Link to="mailto:info@eshoppingadvisor.com">
                    info@eshoppingadvisor.com
                  </Link>{" "}
                  potrete chiedere spiegazioni e proporre reclamo nel caso in
                  cui non siate in accordo con la rimozione del Vostro messaggio
                  e/o recensione.
                </em>
              </p>
              <p>
                <em>
                  L'utente accetta esplicitamente di non pubblicare, caricare,
                  trasmettere, distribuire, archiviare, creare o altrimenti
                  pubblicare contenuti falsi o in violazione dei Termini
                  contrattuali /
                  <Link to="/manleva">
                    https://www.eshoppingadvisor.com/it/manleva
                  </Link>
                </em>
              </p>
              <p>
                <em>
                  <em>World Advisor&nbsp;Srl</em>&nbsp;declina ogni
                  responsabilità e non fornisce alcuna garanzia rispetto alle
                  recensioni pubblicate, archiviate o caricate dall'utente o da
                  terze parti.
                </em>
              </p>
              <p>
                <em>
                  <em>World Advisor&nbsp;Srl</em>&nbsp;declina inoltre ogni
                  responsabilità in caso di errori, diffamazioni, ingiurie,
                  calunnie, omissioni, false dichiarazioni, linguaggio osceno,
                  pornografico o profano. L'utente resta il solo responsabile
                  della recensione assumendosene tutte le responsabilità
                </em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
