import React from "react"
import { Link } from "react-router-dom"
import { Shop } from "../../../models/base/shop.interface"
import { formatDate, toRatingText } from "../../../utils/stringUtils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAppDispatch } from "../../../app/hooks"
import { setEsaScoreModal } from "../../modals/modalsSlice"
import thumbPlaceholder from "../../../assets/images/thumb_placeholder.png"
import { isPremiumShop, getShopThumb } from "../../../utils/shopUtils"
import { useGetFeedInfoQuery } from "../../../app/services/esaScraper/general"
import { useGetReviewsStatsQuery } from "../../../app/services/base/reviews"
import { RatingStars } from "../../rating_stars/RatingStars"

interface EShopDetailsProps {
  shop: Shop
}

export const EShopDetails: React.FC<EShopDetailsProps> = ({ shop }) => {
  const dispatch = useAppDispatch()

  const { data: feedInfo } = useGetFeedInfoQuery({
    domainId: shop.id,
    kindOfFeed: "product",
  })

  const { data: reviewsStats, isLoading } = useGetReviewsStatsQuery({
    eshopId: shop.id,
    since: formatDate(new Date(new Date().setMonth(new Date().getMonth() - 3))),
    until: formatDate(new Date()),
  })

  let eshopUrl =
    (shop.eshopData.url.includes("http") ? "" : "http://") + shop.eshopData.url

  let planName = shop.plan
  let planNameColor = planName === "basic" ? "free" : planName
  let planColor = planNameColor + "Background"
  return (
    <div className="d-flex flex-column flex-lg-row align-items-center mb-3">
      <div
        className="badge-white border-0 mt-4 mb-4 mt-lg-0 mb-lg-0 me-lg-3 height-200 width-200 overflow-hidden d-flex justify-content-center"
        style={{ minWidth: "200px" }}
      >
        <Link
          to={eshopUrl}
          target="_blank"
          className="text-decoration-none"
          {...(isPremiumShop(shop.plan) ? {} : { rel: "nofollow" })}
        >
          <img
            id="eshop-thumb"
            src={thumbPlaceholder}
            data-src={getShopThumb(shop.id)}
            className="img-fluid h-100"
            loading="lazy"
            alt={`Recensioni ${shop.domain}`}
            onError={(e) => {
    e.currentTarget.src = thumbPlaceholder;
    e.currentTarget.dataset.src = '';
  }}
            onLoad={(e) => {
              if (
                e.currentTarget.dataset.src &&
                e.currentTarget.src !== e.currentTarget.dataset.src
              ) {
                e.currentTarget.src = e.currentTarget.dataset.src
              }
            }}
          />
        </Link>
      </div>
      <div className="d-flex flex-column w-100 mt-lg-0">
        <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center">
          <Link
            to={eshopUrl}
            target="_blank"
            className="text-decoration-none line-clamp"
            {...(isPremiumShop(shop.plan) ? {} : { rel: "nofollow" })}
          >
            <h1 className="bolder-dark-title font-33 mb-3 me-lg-3 orange-hover">
              {shop.domain}
            </h1>
          </Link>
          {planName != "ibrido" && (
            <span
              className={`badge rounded-pill mb-4 mb-lg-3 ${
                planColor ? planColor : ""
              }`}
            >
              {planName == "ibrido" ? "Ibrido" : planName}
            </span>
          )}
        </div>
        <div className="d-flex justify-content-center justify-content-lg-start align-items-center mb-3">
          <div className="me-2">
            <RatingStars
              rating={shop.rating ? shop.rating : 0}
              showAverageRating
              size="small"
            />
          </div>
          <div className="font-semi-bolder simple-orange-txt font-16 me-2">
            {shop.rating && shop.rating > 0
              ? toRatingText(shop.rating)
              : "ESA score"}
          </div>
          <div
            className="d-flex align-items-center justify-content-center cursor-pointer"
            style={{
              border: "1px solid #1e3d87",
              borderRadius: "30px",
              gap: "1px",
              padding: "5px 15px",
            }}
            onClick={() => dispatch(setEsaScoreModal(shop))}
          >
            <FontAwesomeIcon
              icon={["fas", "info"]}
              style={{ fontSize: "10px", color: "#1e3d87" }}
            />
            {/* <span className="d-lg-none font-9" style="color: #163c8c">informazioni</span> */}
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-lg-start mb-3">
          <div className="font-14 dark-color font-lighter">
            {reviewsStats && reviewsStats.total_reviews > 0 ? (
              <>
                Ultimi 3 mesi:{" "}
                <span id="last-months-container" className="font-semi-bolder">
                  <span id="val-last-months">
                    {reviewsStats.average_rating}/5
                  </span>
                  <span>
                    {" "}
                    (su{" "}
                    <span id="nrevs-last-months" className="hide">
                      {reviewsStats.total_reviews}
                    </span>{" "}
                    recensioni){" "}
                  </span>
                </span>
              </>
            ) : (
              "Nessuna recensione negli ultimi 3 mesi"
            )}
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start">
          {shop.eshopData.isDisabled ? (
            <span className="btn btn-gradient-orange btn-ecommerce-details py-2 mb-3 mb-lg-0 me-lg-2 d-flex align-items-center justify-content-center disabled">
              Scrivi recensione
            </span>
          ) : (
            <Link
              to={`/invia-recensione?e=${shop.id}`}
              className="btn btn-animation btn-action btn-gradient-orange btn-ecommerce-details py-2 mb-3 mb-lg-0 me-lg-2 d-flex align-items-center justify-content-center"
              title={`Scrivi recensione di ${shop.domain.replace("*", "/")}`}
            >
              Scrivi recensione
            </Link>
          )}
          {feedInfo && feedInfo.product_count && feedInfo.product_count > 0 ? (
            <Link
              to={`/prodotti-eshop/${shop.domain.replace("/", "*")}`}
              className="btn btn-outline-animation btn-outline-gradient-orange btn-ecommerce-details py-2 d-flex align-items-center justify-content-center"
              title={`Tutti i prodotti di ${shop.domain.replace("*", "/")}`}
            >
              Vedi tutti i prodotti
            </Link>
          ) : (
            <Link
              to={
                shop.eshopData.structured_data.urlAff
                  ? shop.eshopData.structured_data.urlAff
                  : shop.eshopData.url
              }
              target="_blank"
              className="btn btn-outline-animation btn-outline-gradient-orange btn-ecommerce-details d-flex justify-content-center align-items-center py-2"
              {...(isPremiumShop(shop.plan) ? {} : { rel: "nofollow" })}
            >
              <span
                className="d-flex flex-wrap text-start simple-orange-txt-link me-3 line-clamp break-all"
                style={{ whiteSpace: "normal" }}
              >
                {" "}
                Visita il sito{" "}
              </span>
              <span>
                <FontAwesomeIcon
                  icon={["fas", "arrow-right-long"]}
                  className="text-center align-middle thin-icon font-18"
                />
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
