import React, { ChangeEvent, FormEvent, useState } from "react"
import background from "../../assets/images/reviewer_ranking/top_recensori_bg.png"
import reviewerRankingCss from "./reviewerRanking.css?inline"
import { Link, useSearchParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useGetReviewersQuery } from "../../app/services/base/general"
import { ReviewerRankingItem } from "./ReviewerRankingItem"
import { Helmet } from "react-helmet"

export const ReviewerRanking: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParam = searchParams.get("s")
  const userId = searchParams.get("p")
  const [page, setPage] = useState<number>(1)
  const [searchText, setSearchText] = useState<string>(
    searchParam ? searchParam : "",
  )
  const [nameParam, setNameParam] = useState<string>(
    searchParam ? searchParam : "",
  )
  const [orderBy, setOrderBy] = useState<string>("1")

  const { data: reviewers } = useGetReviewersQuery({
    order: orderBy,
    name: nameParam,
    id: userId ? userId : undefined,
  })

  function handlePageChange(page: number) {
    setPage(page)
  }

  function handleOrderChange(e: ChangeEvent<HTMLSelectElement>) {
    setOrderBy(e.target.value)
  }

  function handleSearchChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget
    setSearchText(value)
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setNameParam(searchText)
  }

  return (
    <>
      <Helmet>
        <title>
          I migliori recensori di siti e-commerce sono su eShoppingAdvisor
        </title>
        <meta
          name="description"
          content="Diventa anche tu un micro influencer dello shopping online!"
        />
      </Helmet>
      <section className="top-recensori-page">
        <div className="container">
          <div className="top_recensori_form">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <h1>
                  I migliori recensori di siti e-commerce sono su
                  eShoppingAdvisor
                </h1>
                <h2>
                  Diventa anche tu un micro influencer dello shopping online!
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <img
                  src={background}
                  className="top_recensori_img"
                  alt="Classifica recensori"
                />
              </div>
            </div>
            <form className="recensori-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-md-3 top_recensori_search_row">
                  <input
                    value={searchText}
                    onChange={handleSearchChange}
                    type="text"
                    name="s"
                    className="top_recensori_search"
                    placeholder="Cerca recensore"
                  />
                  <button type="submit" className="top_recensori_submit">
                    Cerca recensore
                  </button>
                </div>
              </div>
            </form>

            <div className="row align-items-center my-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
                {searchParam ||
                  (userId && (
                    <Link to="/classifica-recensori" className="reset-rank">
                      <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Torna
                      alla classifica generale
                    </Link>
                  ))}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                <label className="rank-order-label">
                  Ordina recensori per:{" "}
                </label>
                <select name="o" value={orderBy} onChange={handleOrderChange}>
                  <option value="1">Carriera recensioni</option>
                  <option value="2">Carriera visualizzazioni</option>
                </select>
              </div>
            </div>
          </div>
          <div className="recensori-list">
            {reviewers && reviewers.items.length > 0 ? (
              <>
                {reviewers.items.map((reviewer) => (
                  <ReviewerRankingItem
                    key={reviewer.id}
                    reviewer={reviewer}
                    orderBy={orderBy}
                  />
                ))}
                {/* <AppPagination
                currentPage={page}
                totalPages={Number(reviewers?.pagination.total_count)}
                handlePageChange={handlePageChange}
              /> */}
              </>
            ) : (
              <>
                {userId ? (
                  <div className="my-5 p-3 text-center">
                    <p> Non sei ancora in classifica... </p>
                    <Link to="/scrivi-recensione" className="btn btn-cta">
                      Scrivi la tua prima recensione!
                    </Link>
                  </div>
                ) : (
                  <span className="empty">
                    <br />
                    <div className="well">Nessun recensore trovato</div>
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <style>{reviewerRankingCss}</style>
      </section>
    </>
  )
}
