import React, { useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import mascotteHover from "../../assets/images/mascotte_hover.gif"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../../features/auth/authSlice"
import {
  useGetCouponQuery,
  useGetCouponsQuery,
} from "../../app/services/base/coupons"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetShopByIdQuery } from "../../app/services/base/shops"
import { CouponTicket } from "../../features/coupon_ticket/CouponTicket"

const getNextLevel = (level: string) => {
  const awardsArray = [
    { level: "Nuovo Utente", points: 1 },
    { level: "Nuovo Consulente", points: 3 },
    { level: "Consulente", points: 5 },
    {
      level: "Consulente di riferimento",
      points: 20,
    },
    { level: "Consulente Top", points: 50 },
  ]
  try {
    const levelIndex = awardsArray.findIndex((a) => a.level == level)
    const nextLevel = awardsArray[levelIndex + 1]

    return nextLevel
  } catch (error) {
    return undefined
  }
}

export const InsertReviewOk: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const [searchParams, setSearchParams] = useSearchParams()
  const reviewId = searchParams.get("id")
  const eShopUrlParam = searchParams.get("e")
  const isDomain = eShopUrlParam?.includes(".")
  const fromInvitation = searchParams.get("v")
  const couponId = searchParams.get("c")
  // Se arriviamo qui con invito alla recensione valido, allora il coupon lo recuperiamo per ID,
  // altrimenti il coupon prendiamo quello omaggio dallo shop
  const { data: couponWithShop } =
    fromInvitation == "1"
      ? useGetCouponQuery(couponId ? couponId : skipToken)
      : useGetCouponsQuery(
          eShopUrlParam && !isDomain
            ? { gift: 1, page: 1, perPage: 1 }
            : skipToken,
          {
            selectFromResult: ({ data }) => ({
              data: data?.items.find(
                (couponWithShop) =>
                  couponWithShop.eshop.id.toString() == eShopUrlParam!,
              ),
            }),
          },
        )

  const { data: shop } = useGetShopByIdQuery(
    eShopUrlParam && !isDomain ? eShopUrlParam : skipToken,
  )

  const points = fromInvitation == "1" ? 1 : 0.25
  const nextLevel = user ? getNextLevel(user?.reviews_career) : undefined
  const nextPoints =
    nextLevel && user && user.points ? nextLevel.points - user?.points : 0

  useEffect(() => {
    const confettiPlayers: any = []

    const makeItConfetti = () => {
      const confettiContainer = document.querySelector(".confetti-container")

      for (let i = 0; i <= 80; i++) {
        const confettiElement = document.createElement("div")
        confettiElement.className = "confetti"
        confettiContainer?.appendChild(confettiElement)
      }

      const confetti = document.querySelectorAll(".confetti")

      if (!confetti[0].animate) {
        return false
      }

      confetti.forEach((candycorn, index) => {
        candycorn.innerHTML =
          '<div class="rotate"><div class="pillow"></div></div>'
        const scale = Math.random() * 0.7 + 0.3
        const player = candycorn.animate(
          [
            {
              transform: `translate3d(${
                (index / confetti.length) * 100
              }vw,-5vh,0) scale(${scale}) rotate(0turn)`,
              opacity: scale,
            },
            {
              transform: `translate3d(${
                (index / confetti.length) * 100 + 10
              }vw,105vh,0) scale(${scale}) rotate(${
                Math.random() > 0.5 ? "" : "-"
              }2turn)`,
              opacity: 1,
            },
          ],
          {
            duration: Math.random() * 3000 + 5000,
            iterations: Infinity,
            delay: -(Math.random() * 7000),
          },
        )

        confettiPlayers.push(player)
      })
    }

    makeItConfetti()

    return () => {
      confettiPlayers.forEach((player: any) => player.cancel())
    }
  }, [])

  return (
    <>
      <div
        style={{
          background: "linear-gradient(225deg, #e55e0e 0%, #ffb100 100%)",
          zIndex: "9",
          position: "relative",
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column align-items-center justify-content-center p-3 p-lg-4 container-custom">
            <h1 className="font-33 lg-font-47 font-bolder simple-white-txt m-0 mb-3">
              Complimenti!
            </h1>
            <p className="font-16 lg-font-23 simple-white-txt text-center m-0">
              Hai appena recensito il sito{" "}
              {shop
                ? eShopUrlParam && !isDomain
                  ? shop.domain
                  : eShopUrlParam
                : ""}
              ,{" "}
              {fromInvitation == "1" && !user ? (
                <>
                  <Link
                    to="/registrazione/cliente"
                    className="white-color font-bolder"
                  >
                    registrati subito
                  </Link>{" "}
                  e inizia a guadagnare punti.
                </>
              ) : (
                "resta connesso e fai sapere alla comunity delle tue esperienze."
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column align-items-center justify-content-center w-100 lg-w-50">
            <div className="position-relative mb-3">
              <img
                src={mascotteHover}
                alt="Volpe eShoppingAdvisor"
                width="100%"
                style={{ maxWidth: "442px", marginTop: "-50px" }}
              />
              <div className="d-flex flex-column align-items-center justify-content-center gap-2 w-100 position-absolute bottom-0 start-50 translate-middle-x">
                <p className="font-23 lightgray-txt font-bolder text-center m-0">
                  {fromInvitation == "1" && !user
                    ? "Avresti guadagnato"
                    : "Hai guadagnato"}
                </p>
                <p className="font-33 simple-orange-txt font-bolder text-center m-0">
                  {points} pt ESA
                </p>
              </div>
            </div>
            {fromInvitation == "1" && !user ? (
              <p className="lightgray-txt text-center m-0 mb-4 mb-lg-5">
                <Link
                  to="/registrazione/cliente"
                  className="simple-blue-link orange-hover font-bolder"
                >
                  Registrati
                </Link>
                , interagisci con la community e sblocca fantastici premi da
                utilizzare nei nostri eCommerce!
              </p>
            ) : nextPoints > 0 ? (
              <p className="lightgray-txt text-center m-0 mb-4 mb-lg-5">
                Mancano solo {nextPoints} pt al prossimo traguardo, interagisci
                con la community e sblocca fantastici premi da utilizzare nei
                nostri eCommerce!
              </p>
            ) : (
              <></>
            )}
            {couponWithShop && (
              <div className="custom-container mx-auto">
                <div className="col-12 text-center">
                  <h2 className="title title dark-color font-23 font-bolder">
                    Ecco il tuo coupon!
                  </h2>
                  <div className="col-12">
                    <ul className="list-group list-style-none">
                      <CouponTicket
                        couponWithShop={couponWithShop}
                        isUnlocked={true}
                        itemCustomClass="coupon-border-bg-white"
                      />
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <Link
              to="/"
              className="btn btn-outline-gray d-flex align-items-center justify-content-center w-100 mt-3 mt-lg-2 mb-4"
              style={{ height: "50px" }}
            >
              Torna alla Home
            </Link>
          </div>
        </div>
      </div>
      <div className="confetti-container"></div>
    </>
  )
}
