import React from "react"
import { Helmet } from "react-helmet"
import { formatNumber } from "../../utils/stringUtils"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { ReviewUserUnion } from "../../models/base/reviewUserUnion.interface"

interface ProductSchemaProps {
  product: EsaScraperProduct
  reviews?: ReviewUserUnion[]
  isAffiliated: boolean
}

export const ProductSchema: React.FC<ProductSchemaProps> = ({
  product,
  reviews,
  isAffiliated,
}) => {
  let reviewsArray = [] as any

  reviews?.forEach(({ review, user }) => {
    reviewsArray.push({
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        headline: review.title.replace('"', "'"),
        reviewBody: review.description.replace('"', "'"),
        ratingValue: review.overall_rating ? review.overall_rating : 0,
        bestRating: 5,
      },
      author: {
        "@type": "Person",
        name: user.name,
      },
      publisher: {
        "@type": "Organization",
        name: "eShoppingAdvisor",
        url: import.meta.env.BASE_URL,
        logo: {
          "@type": "ImageObject",
          url: "https://www.eshoppingadvisor.com/logo.png",
          width: 1200,
          height: 1200,
          caption: "eShoppingAdvisor logo",
        },
      },
    })
  })

  let arrayReviewsJson =
    reviewsArray && reviewsArray.length > 0
      ? '"review":' + JSON.stringify(reviewsArray) + ","
      : ""

  return (
    <Helmet>
      <script type="application/ld+json">
        {`
            {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": "${product.product_name.replace(/"/g, "'")}",
                "image": "${
                  product.cdn_image_url
                    ? product.cdn_image_url
                    : product.image_url
                }",
                "description": "${product.description.replace(/"/g, "'")}",
                ${arrayReviewsJson}
                ${
                  reviews && reviews.length > 0
                    ? `"aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": ${product.score ? product.score : "0"},
                    "reviewCount": ${product.reviews ? product.reviews : "0"}
                },`
                    : ""
                }
                "offers": {
                    "@type": "Offer",
                    ${
                      !isAffiliated && !product.has_affiliation
                        ? `
                    "url": "${product.url}",
                    `
                        : ""
                    }
                    "priceCurrency": "EUR",
                    "price": "${
                      product.price
                        ? formatNumber(product.price, 2)
                        : product.high_price
                        ? formatNumber(product.high_price, 2)
                        : "0"
                    }",
                    "availability": "https://schema.org/InStock"
                }
            }
        `}
      </script>
    </Helmet>
  )
}
