import React from "react"
import { Link } from "react-router-dom"
import { getShopLogo, getShopThumb } from "../../../utils/shopUtils"
import { SuggestedShop } from "../../../models/base/suggestedShop.interface"
import shopPlaceholderLogo from "../../../assets/images/thumb_placeholder.png"
import { RatingStars } from "../../../features/rating_stars/RatingStars"

interface SuggestedShopProps {
  suggestedShop: SuggestedShop
}

export const SuggestedShopItem: React.FC<SuggestedShopProps> = ({
  suggestedShop,
}) => {
  return (
    <div className="item card border-radius-75 p-3 me-0 me-md-2">
      <div className="d-flex justify-content-center text-center py-3">
        <Link
          to={`/eshop/${suggestedShop.domain}`}
          className="btn btn-simple-link"
          title={suggestedShop.domain}
        >
          <img
            src={getShopLogo(suggestedShop.id)}
            className="img-fluid height-50 width-auto"
            style={{ height: "50px" }}
            alt={suggestedShop.domain}
            loading="lazy"
            onError={(e) => (e.currentTarget.src = shopPlaceholderLogo)}
          />
        </Link>
      </div>
      <div className="py-3 mx-auto">
        <RatingStars
          rating={suggestedShop.rating ? suggestedShop.rating : 0}
          showAverageRating={true}
        />
        <div className="d-block">
          <Link
            to={`/eshop/${suggestedShop.domain}`}
            className="simple-orange-link"
            title={suggestedShop.domain}
          >
            {suggestedShop.domain}
          </Link>
        </div>
      </div>
      <div className="rounded">
        <Link
          to={`/eshop/${suggestedShop.domain}`}
          className="simple-orange-link"
          title={suggestedShop.domain}
        >
          <img
            src={getShopThumb(suggestedShop.id)}
            className="img-fluid rounded eShopCardCarouselThumb border-radius-75 border-gray"
            alt={suggestedShop.domain}
            loading="lazy"
            onError={(e) => (e.currentTarget.src = shopPlaceholderLogo)}
          />
        </Link>
      </div>
      <div className="py-3">
        {suggestedShop.nProducts == 0 ? (
          <Link
            to={`/eshop/${suggestedShop.domain}`}
            className="btn btn-outline-animation btn-outline-gradient-orange w-100 py-2 font-bolder"
            title={`Vetrina ${suggestedShop.domain}`}
          >
            Vedi vetrina
          </Link>
        ) : (
          <Link
            to={`/prodotti-eshop/${suggestedShop.domain}`}
            className="btn btn-outline-animation btn-outline-gradient-orange w-100 py-2 font-bolder"
            title={`Tutti i prodotti di ${suggestedShop.domain}`}
          >
            Vedi prodotti
          </Link>
        )}
      </div>
      <div className="py-1">
        <span className="lightgray-txt">
          {" "}
          {suggestedShop.nReviews} Recensioni{" "}
        </span>
        <Link
          to={`/eshop/${suggestedShop.domain}`}
          className="simple-secondary-link float-end"
          title={`Recensioni ${suggestedShop.domain}`}
        >
          {" "}
          Leggi tutte{" "}
        </Link>
      </div>
    </div>
  )
}
