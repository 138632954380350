import React from "react"
import letterBox from "../../assets/icons/letter_box.png"
import registrationConfirmedCss from "./registrationConfirmed.css?inline"

export const RegistrationConfirmed: React.FC = () => {
  return (
    <section className="access-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="access-box">
              <div className="create-ok-body reg_container">
                <h3 className="log-a">Ci siamo quasi!</h3>
                <img src={letterBox} className="img-fluid" />
                <p className="create-ok-text">
                  La richiesta di registrazione è stata inoltrata con successo!
                  Per completare l’operazione segui le istruzioni contenute
                  nell’email che ti abbiamo inviato
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{registrationConfirmedCss}</style>
    </section>
  )
}
