import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectReportModal, setReportModal } from "./modalsSlice"
import { Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Review } from "../../models/base/review.interface"
import parse from "html-react-parser"
import { selectCurrentUser } from "../auth/authSlice"
import shopPlaceholderLogo from "../../assets/images/thumb_placeholder.png"
import { getShopLogo } from "../../utils/shopUtils"
import { useSendReportMutation } from "../../app/services/base/reviews"
import { addToast } from "../toasts/toastsSlice"

type Motivation = {
  text: string
  value: string
  disclaimerText?: string
  disclaimers?: string[]
  subMotivations?: Motivation[]
}

interface StepTwoProps {
  review: Review | undefined
}

const StepTwo: React.FC<StepTwoProps> = ({ review }) => {
  const dispatch = useAppDispatch()
  const [sendReport] = useSendReportMutation()
  const [motivationValue, setMotivationValue] = useState<string>("")
  const [subMotivationValue, setSubMotivationValue] = useState<string>("")
  const [selectedMotivation, setSelectedMotivation] = useState<Motivation>({
    text: "",
    value: "",
  })
  const [isReported, setIsReported] = useState<boolean>(false)
  const motivations: Motivation[] = [
    {
      text: "Non è coerente con la valutazione espressa",
      value: "non_coerente",
      disclaimerText: "Possiamo rimuovere una recensione, se:",
      disclaimers: [
        "Il testo della recensione non è coerente con le stelline assegnate dall'utente",
      ],
    },
    {
      text: "Non si basa su un'esperienza autentica",
      value: "non_autentica",
      disclaimerText: "Possiamo rimuovere una recensione, se questa:",
      disclaimers: [
        "È stata scritta per conto di qualcun altro che ha avuto l'esperienza",
        "Descrive un'esperienza inventata.",
      ],
    },
    {
      text: "Si riferisce a un'altra azienda",
      value: "altra_azienda",
      disclaimerText: "Si riferisce a un'altra azienda perché?",
      subMotivations: [
        {
          text: "Si riferisce ad un altro dominio gestito sempre dalla stessa azienda e/o titolare",
          value: "altro_dominio",
        },
        {
          text: "Si riferisce a un'azienda completamente diversa",
          value: "azienda_diversa",
        },
      ],
    },
    {
      text: "Contiene pubblicità o promozioni",
      value: "pub_promo",
      disclaimerText: "Possiamo rimuovere una recensione, se questa contiene:",
      disclaimers: [
        "Codici promozionali o sconti",
        "Inviti a formare o unirsi a gruppi di azione di consumatori",
        "Inviti ad acquistare altrove.",
      ],
    },
    {
      text: "Contiene informazioni personali",
      value: "info_personali",
      disclaimerText:
        "Possiamo rimuovere una recensione che viola le leggi sulla privacy in vigore.<br/><b>Ad esempio possiamo rimuovere recensioni che contengono informazioni che identificano un dipendente senza il suo consenso come:</b>",
      disclaimers: [
        "Nome e cognome",
        "Numero di telefono",
        "Indirizzo di residenza",
        "Indirizzo email",
      ],
    },
    {
      text: "È nociva o illegale",
      value: "nociva",
      disclaimerText: "È nociva o illegale perché?",
      subMotivations: [
        { text: "Linguaggio inappropriato", value: "linguaggio_inappropriato" },
        { text: "Minacce o violenza", value: "minacce" },
        { text: "Oscenità", value: "oscenita" },
        { text: "Diffamazione", value: "diffamazione" },
      ],
    },
    {
      text: "Nessuno dei motivi precedenti",
      value: "nessuno",
      disclaimerText:
        "I motivi di segnalazione si basano sulle nostre condizioni di utilizzo e procediamo alla rimozione di una recensione solo se le viola.",
      disclaimers: [
        "Non sei d’accordo con la versione dei fatti fornita dall’autore delle recensione o con la valutazione in stelle attribuita.",
        "Non menziona specificamente la tua azienda oppure un tuo prodotto o servizio.",
        "Menziona un’altra azienda, prodotto o servizio in un contesto pertinente.",
      ],
    },
  ]

  async function handleSendReport() {
    let stringValue = selectedMotivation.text
    if (subMotivationValue) {
      stringValue +=
        "-" +
        selectedMotivation.subMotivations?.find(
          (sub) => sub.value == subMotivationValue,
        )?.text
    }

    try {
      const response = await sendReport({
        reviewId: review!.id,
        reason: stringValue,
      }).unwrap()
      if (!response.id) throw new Error()
      setIsReported(true)
      // dispatch(
      //   addToast({
      //     message: "Grazie per aver segnalato la recensione.",
      //     type: "success",
      //   }),
      // )
      // dispatch(setReportModal(undefined))
    } catch (error) {
      dispatch(
        addToast({
          message: "Oops! Impossibile segnalare la recensione al momento.",
          type: "danger",
        }),
      )
    }
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div className="d-flex align-items-center justify-content-center d-lg-none mb-4">
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={() => dispatch(setReportModal(undefined))}
        ></button>
      </div>
      <div className="card p-3 p-lg-4 w-100" style={{ borderRadius: "20px" }}>
        <div className="d-flex flex-column align-items-center justify-content-center border-0">
          {!isReported &&
            selectedMotivation.value == "" &&
            !selectedMotivation.subMotivations && (
              <>
                <h5 className="titleReports_43387 font-33 font-bolder dark-color text-center w-100 m-0 mt-2 mt-lg-3 mb-4 px-lg-5">
                  Indicaci una motivazione
                </h5>
                <p className="titleReports_43387 dark-color font-23 font-semi-bolder m-0 mt-0 mt-lg-3 mx-lg-3 px-lg-4">
                  Perché stai segnalando questa recensione?
                </p>
              </>
            )}
          <p className="dark-color font-23 font-semi-bolder m-0 mt-0 mt-lg-3 mx-lg-3 px-lg-4">
            {" "}
            <span id="titolo_motivazione_43387">
              {!isReported &&
                selectedMotivation.value != "" &&
                // !selectedMotivation.subMotivations &&
                selectedMotivation.value != "nessuno" &&
                selectedMotivation.value != "info_personali" &&
                selectedMotivation.value != "non_coerente" &&
                selectedMotivation.value != "non_autentica" &&
                selectedMotivation.value != "pub_promo" &&
                selectedMotivation.disclaimerText}
            </span>{" "}
          </p>
          <button
            type="button"
            className="btn-close btn-close-absolute d-none d-lg-block position-absolute p-0"
            onClick={() => dispatch(setReportModal(undefined))}
          ></button>
        </div>
        {!isReported && selectedMotivation.value == "" && (
          <div id="reports_43387" className="mx-lg-3 mb-3 px-lg-4">
            <ul className="list-group list-group-flush border-bottom-fade">
              {motivations.map((motivation) => (
                <li
                  key={motivation.value}
                  className="list-group-item d-flex justify-content-between align-items-center p-0 py-3 py-lg-4"
                >
                  <label
                    className="form-check-label lightgray-txt font-16 stretched-link"
                    htmlFor={`radio_${motivation.value}_${review?.id}`}
                    data-group="reportCheckbox"
                  >
                    {motivation.text}
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="reportCheckbox"
                    value={motivation.value}
                    id={`radio_${motivation.value}_${review?.id}`}
                    checked={motivationValue == motivation.value}
                    onChange={(e) => {
                      setMotivationValue(e.currentTarget.value)
                      setSubMotivationValue("")
                    }}
                    style={{ width: "20px", height: "20px" }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        {!isReported && selectedMotivation.value != "" && (
          <div id="subReports_43387" className="mx-lg-3 mb-3 px-lg-4">
            {selectedMotivation.subMotivations ? (
              <ul className="list-group list-group-flush border-bottom-fade">
                {selectedMotivation.subMotivations.map((subMotivation) => (
                  <li
                    key={subMotivation.value}
                    className="list-group-item d-flex justify-content-between align-items-center p-0 py-3 py-lg-4"
                  >
                    <label
                      className="form-check-label lightgray-txt font-16 stretched-link"
                      htmlFor={`radio_${subMotivation.value}_${review?.id}`}
                      data-group="subReportCheckbox"
                    >
                      {subMotivation.text}
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="subReportCheckbox"
                      value={subMotivation.value}
                      id={`radio_${subMotivation.value}_${review?.id}`}
                      checked={subMotivationValue == subMotivation.value}
                      onChange={(e) =>
                        setSubMotivationValue(e.currentTarget.value)
                      }
                      style={{ width: "20px", height: "20px" }}
                    />
                  </li>
                ))}
              </ul>
            ) : selectedMotivation.value != "nessuno" ? (
              <div className="px-lg-4 mx-lg-3">
                <p className="font-23 font-semi-bolder dark-color m-0">
                  {selectedMotivation.value != "info_personali" ? (
                    <>
                      {selectedMotivation.disclaimerText}
                      <br />
                    </>
                  ) : (
                    parse(selectedMotivation.disclaimerText!)
                  )}
                </p>
                <ul className="font-23 dark-color m-0">
                  {selectedMotivation.disclaimers?.map((disclaimer) => (
                    <li key={disclaimer}>{disclaimer}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <>
                <div className="d-flex flex-column align-items-center justify-content-center border-0 px-lg-4 mx-lg-3 pt-lg-4 mt-lg-3">
                  <p className="report-modal-text dark-color m-0 mt-2 mb-3">
                    {selectedMotivation.disclaimerText}
                  </p>
                </div>
                <div className="px-lg-4 mx-lg-3">
                  <p className="font-23 font-semi-bolder dark-color m-0">
                    Ricorda che non possiamo rimuovere una recensione soltanto
                    perchè:
                  </p>
                  <ul className="font-23 dark-color m-0">
                    {selectedMotivation.disclaimers?.map((disclaimer) => (
                      <li key={disclaimer}>{disclaimer}</li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        )}
        {isReported && (
          <div className="modal-body mx-3" id="thankyou_page43387">
            <h5 className="font-33 font-bolder dark-color text-center w-100 m-0 mt-2 mt-lg-3 mb-4 px-lg-5">
              {" "}
              Grazie per la tua segnalazione{" "}
            </h5>
            <p className="dark-color font-23 m-0 mt-0 mt-lg-3 mx-lg-3 px-lg-4">
              {" "}
              I feedback come il tuo ci aiutano a contrastare le recensioni
              false e a garantire l'affidabilità del nostro portale.{" "}
            </p>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between border-0 py-2 mt-4 mt-lg-5 mb-lg-5 px-lg-4 mx-lg-3">
          {!isReported &&
            motivationValue != "" &&
            selectedMotivation.value == "" && (
              <div id="next-btn_43387" className="w-100">
                <button
                  id="fake-next-btn_43387"
                  className="btn btn-gradient-orange btn-ecommerce-details d-flex align-items-center justify-content-center mx-auto"
                  style={{ height: "50px", width: "48%" }}
                  disabled={!motivationValue ? true : false}
                  onClick={() =>
                    setSelectedMotivation(
                      motivations.find((m) => m.value == motivationValue)!,
                    )
                  }
                >
                  Prosegui
                </button>
              </div>
            )}
          {!isReported && selectedMotivation.value != "" && (
            <button
              id="fake-prev-btn_43387"
              className="btn btn-outline-gray font-16 font-semi-bolder py-2 d-flex align-items-center justify-content-center m-0"
              style={{ height: "50px", width: "48%" }}
              onClick={() => setSelectedMotivation({ text: "", value: "" })}
            >
              Indietro
            </button>
          )}
          {!isReported && selectedMotivation.value != "" && (
            <>
              {selectedMotivation.value == "nessuno" ? (
                <button
                  id="fake-close-btn_43387"
                  className="btn btn-gradient-orange py-2 d-flex align-items-center justify-content-center m-0"
                  style={{ height: "50px", width: "48%" }}
                  onClick={() => dispatch(setReportModal(undefined))}
                >
                  Ho capito
                </button>
              ) : (
                <button
                  id="fake-submit-btn_43387"
                  className="btn btn-gradient-orange py-2 d-flex align-items-center justify-content-center m-0"
                  style={{ height: "50px", width: "48%" }}
                  disabled={
                    (selectedMotivation.value == "altra_azienda" ||
                      selectedMotivation.value == "nociva") &&
                    !subMotivationValue
                      ? true
                      : false
                  }
                  onClick={handleSendReport}
                >
                  Invia
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export const ReportModal: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const reviewShop = useAppSelector(selectReportModal)
  const dispatch = useAppDispatch()
  const [step, setStep] = useState<number>(1)
  return (
    <>
      <Modal
        show={reviewShop ? true : false}
        onHide={() => dispatch(setReportModal(undefined))}
        size="lg"
        centered
        aria-labelledby="reportModalLabel"
        dialogClassName="justify-content-center"
        contentClassName="bg-transparent position-relative border-0"
      >
        {step == 1 && (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center d-lg-none mb-4">
              <button
                type="button"
                className="btn-close btn-close-white"
                aria-label="Close"
                onClick={() => dispatch(setReportModal(undefined))}
              ></button>
            </div>
            <div
              className="card d-flex p-3 p-lg-4"
              style={{ borderRadius: "20px" }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center border-0">
                <h5 className="font-33 font-bolder dark-color text-center mt-2 mt-lg-3 mb-2">
                  Segnala recensione
                </h5>
                <p className="lightgray-txt text-center font-16 mb-3">
                  Ritieni che ci sia un problema con questa recensione? Faccelo
                  sapere.
                </p>
                <button
                  type="button"
                  className="btn-close btn-close-absolute d-none d-lg-block position-absolute p-0"
                  aria-label="Close"
                  onClick={() => dispatch(setReportModal(undefined))}
                ></button>
              </div>
              <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between justify-content-lg-center gap-3 gap-lg-1 mt-lg-3 mb-0 mb-lg-5 px-lg-5">
                <div className="report-modal-card d-flex flex-column align-items-center justify-content-between p-3 me-0 me-lg-1">
                  <img
                    src={getShopLogo(
                      reviewShop?.eshop.id ? reviewShop.eshop.id : 0,
                    )}
                    alt="Logo eShop"
                    className="mt-0 mt-lg-2"
                    style={{ width: "150px" }}
                    onError={(e) => (e.currentTarget.src = shopPlaceholderLogo)}
                  />
                  <div>
                    <p className="text-center lightgray-txt report-modal-card-text m-0 px-0 px-lg-3">
                      Lavori per{" "}
                      <span className="dark-color font-bolder">
                        {reviewShop?.eshop.domain}
                      </span>
                      ?
                    </p>
                    <p className="text-center lightgray-txt report-modal-card-text m-0 px-0 px-lg-3">
                      Puoi usufruire del processo di segnalazione usando il tuo
                      account business.
                    </p>
                  </div>
                  <Link
                    to="/business/areariservata/login"
                    className="btn btn-outline-animation btn-outline-gradient-orange btn-ecommerce-details py-2 d-flex align-items-center justify-content-center mx-2 mb-2 mb-lg-3"
                    style={{ height: "50px" }}
                  >
                    Login / Registrati
                  </Link>
                </div>
                <div className="report-modal-card d-flex flex-column align-items-center justify-content-between p-3 ms-0 ms-lg-1">
                  <div
                    className="d-flex align-items-center justify-content-center rounded-circle text-center p-3 mt-3 mt-lg-4"
                    style={{
                      width: "80px",
                      height: "80px",
                      border: "2px solid #e55e0e",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "flag"]}
                      className="font-32"
                      style={{ color: "#e55e0e" }}
                    />
                  </div>
                  <p className="text-center lightgray-txt font-16 m-0 px-0 px-lg-3">
                    Sei un consumatore e vuoi segnalare questa recensione?
                    Procedi ora!
                  </p>
                  {user ? (
                    <a
                      className="btn btn-gradient-orange btn-ecommerce-details py-2 d-flex align-items-center justify-content-center mx-2 mb-2 mb-lg-3"
                      style={{ height: "50px" }}
                      onClick={() => setStep(2)}
                    >
                      Prosegui
                    </a>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center w-100 mx-2 mb-2 mb-lg-3 gap-3">
                      <Link
                        to={`/areariservata/login`}
                        state={{
                          from: {
                            pathname: `/reviews/${reviewShop?.review.id}`,
                          },
                        }}
                        className="btn btn-outline-animation btn-outline-gradient-orange py-2 d-flex align-items-center justify-content-center w-100"
                        style={{ height: "50px" }}
                      >
                        Login
                      </Link>
                      <Link
                        to={`/registrazione/cliente`}
                        state={{
                          from: {
                            pathname: `/reviews/${reviewShop?.review.id}`,
                          },
                        }}
                        className="btn btn-gradient-orange py-2 d-flex align-items-center justify-content-center w-100"
                        style={{ height: "50px" }}
                      >
                        Registrati
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {step == 2 && <StepTwo review={reviewShop?.review} />}
      </Modal>
    </>
  )
}
