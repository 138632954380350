import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import logoWhite from "../../assets/images/logo-white.png"
import { DropdownMenuHeader } from "./DropdownMenuHeader"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../auth/authSlice"
import { SearchEngineHeaderContainer } from "./SearchEngineHeaderContainer"
import {
  selectSearchIsOpen,
  selectSearchIsScrolled,
  setSearchIsScrolled,
  toggleSearchIsOpen,
} from "../search_engine/searchEngineSlice"
import debounce from "lodash/debounce"

interface HeaderProps {
  isMobileMenuOpen: boolean
  toggleMobileMenu: () => void
}

export const Header: React.FC<HeaderProps> = ({
  isMobileMenuOpen,
  toggleMobileMenu,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectCurrentUser)

  const location = useLocation()
  const isHomePage = location.pathname === "/it"

  const isScrolled = useAppSelector(selectSearchIsScrolled)
  const isOpen = useAppSelector(selectSearchIsOpen)

  const [headerScrolling, setHeaderScrolling] = useState<boolean>(false)

  useEffect(() => {
    // Add scroll event listener to determine when to show in the header
    const debouncedHandleScroll = debounce(() => {
      const scrollY = window.scrollY
      const newHeaderScrolling = scrollY >= 25
      setHeaderScrolling(newHeaderScrolling)

      let searchContainerTop =
        document.getElementById("searchContainer")?.offsetTop ||
        Number.POSITIVE_INFINITY

      // Adjust position based on the previous state
      // The rendered element, add pixel to the body (position: fixed)
      // so this resolve issues on mobile devices
      if (isScrolled) {
        searchContainerTop -= 88 // the height of the added component
      }
      const newSearchScrolling = searchContainerTop <= scrollY

      dispatch(setSearchIsScrolled(newSearchScrolling))
    }, 15)

    window.addEventListener("scroll", debouncedHandleScroll)
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll)
    }
  }, [])

  function handleClick() {
    dispatch(toggleSearchIsOpen())
  }

  return (
    <>
      <div
        // id="header"
        className={
          isHomePage && !isScrolled && !headerScrolling && !isMobileMenuOpen
            ? "header transparent"
            : "header"
        }
        style={!isHomePage ? { marginBottom: "unset" } : {}}
      >
        <div
          // id="header-container"
          className="container py-4"
        >
          <div className="row align-items-center justify-content-between">
            <div className="col-sm-12 col-lg-auto text-center">
              <span
                // id="mobile-menu-trigger"
                className="mobile-menu-trigger d-block d-lg-none menu-mobile-button white-color 
                
                 py-1 px-2"
                onClick={toggleMobileMenu}
              >
                {isMobileMenuOpen ? (
                  <FontAwesomeIcon icon={["fas", "times"]} />
                ) : (
                  <FontAwesomeIcon icon={["fas", "bars"]} />
                )}
              </span>
              {(!isHomePage || isScrolled) && (
                <span
                  // id="mobile-menu-search-trigger"
                  className={`mobile-menu-search-trigger d-block search-bar-mobile-button white-color 
                  
                   py-1 px-2 d-lg-none ${
                     isOpen
                       ? // "btn-gray"
                         ""
                       : "hide-mobile-menu"
                   }`}
                  onClick={handleClick}
                >
                  {isOpen ? (
                    <FontAwesomeIcon
                      // id="mobile-menu-search-icon"
                      icon={["fas", "times"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      // id="mobile-menu-search-icon"
                      icon={["fas", "magnifying-glass"]}
                    />
                  )}
                </span>
              )}
              <picture className="logo-header">
                <Link to="/">
                  <img
                    src={logoWhite}
                    alt="eShoppingAdvisor"
                    className="img-fluid my-1"
                    loading="lazy"
                  />
                </Link>
              </picture>
            </div>
            <div className="col-auto d-none d-lg-flex">
              <Link
                to="/it/categorie"
                className="simple-white-link btn btn-simple-link mx-2"
                rel="nofollow"
              >
                Categorie
              </Link>
              <Link
                to="/il-progetto"
                className="simple-white-link btn btn-simple-link mx-2"
                rel="nofollow"
              >
                Progetto
              </Link>
            </div>
            <div className="col-auto d-none d-lg-flex align-items-center">
              {user ? (
                <DropdownMenuHeader />
              ) : (
                <>
                  <Link
                    to="/areariservata/login"
                    state={{ from: { pathname: "/areariservata/profilo" } }}
                    className="simple-white-link btn btn-simple-link ms-2 pe-3 border-right-1"
                    rel="nofollow"
                  >
                    Accedi
                  </Link>
                  <Link
                    to="/registrazione/cliente"
                    className="simple-white-link btn btn-simple-link me-2"
                    rel="nofollow"
                  >
                    Registrati
                  </Link>
                </>
              )}
              {!isHomePage && (
                <span
                  // id="header-search-trigger"
                  className={`simple-white-link btn btn-simple-link me-3 font-23 ${
                    isOpen ? "simple-orange-txt" : ""
                  }`}
                  onClick={handleClick}
                >
                  {isOpen ? (
                    <FontAwesomeIcon
                      // id="header-search-trigger-icon"
                      icon={["fas", "search-minus"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      // id="header-search-trigger-icon"
                      icon={["fas", "search"]}
                    />
                  )}
                </span>
              )}
              <Link
                to="https://business.eshoppingadvisor.com"
                className="btn btn-gradient-blue py-2 px-auto"
                data-tm="TM_header_links"
              >
                Per gli eCommerce
              </Link>
            </div>
          </div>
        </div>
      </div>
      {(!isHomePage || isScrolled) && isOpen && <SearchEngineHeaderContainer />}
    </>
  )
}
