import React from "react"
import ticketWhite from "../../../../assets/icons/ticket_white.webp"
import ticket from "../../../../assets/icons/ticket.webp"
import tagWhite from "../../../../assets/icons/tag_white.webp"
import tag from "../../../../assets/icons/tag.webp"
import starOrange from "../../../../assets/icons/star_orange.webp"
import { HomeCoupon } from "../../../../models/base/homeCoupon.interface"
import { toStringFromDateTime } from "../../../../utils/stringUtils"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { selectCurrentUser } from "../../../../features/auth/authSlice"
import { setCouponModal } from "../../../../features/modals/modalsSlice"
import { useNavigate } from "react-router-dom"
import { couponsApi } from "../../../../app/services/base/coupons"

interface BootstrapCouponProps {
  isActive: boolean
  coupon: HomeCoupon
  nextCoupons?: any[]
  onSelect: (selectedID: string) => void
}

export const BootstrapCoupon: React.FC<BootstrapCouponProps> = ({
  isActive,
  coupon,
  onSelect,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectCurrentUser)

  async function handleClick() {
    if (user) {
      navigate(`/coupon/ultimi-coupon?coupon=${coupon.eshop_id}`)
    } else {
      const { data: couponWithShop } = await dispatch(
        couponsApi.endpoints.getCoupon.initiate(coupon.coupon_id),
      )
      dispatch(setCouponModal(couponWithShop))
    }
  }

  return (
    <div
      className="col-md-3 col-card-coupon px-3 d-flex align-items-center"
      style={{ minHeight: "289px" }}
      onClick={() => onSelect(coupon.coupon_id)}
    >
      <div
        className={`card card-coupon card-element py-3 px-3 cursor-pointer ${
          isActive ? "active" : ""
        }`}
      >
        <div className="row align-items-center mt-3">
          <div className="col-md-3 justify-content-center">
            <img
              src={
                isActive
                  ? coupon.coupon_tipo_sconto === "1"
                    ? ticketWhite
                    : tagWhite
                  : coupon.coupon_tipo_sconto === "1"
                  ? ticket
                  : tag
              }
              className="height-50 width-auto mx-auto relative-top-10"
              height="50px"
              width="32px"
              alt="ticket"
              loading="lazy"
            />
          </div>
          <div className="col-9">
            <span className="bolder-dark-title xs-font-33 font-47">
              {parseInt(coupon.coupon_sconto, 10)}
              {coupon.coupon_tipo_sconto == "1" ? "%" : "€"}{" "}
            </span>
            <span className="bolder-dark-title font-23 relative-top-20">
              {coupon.coupon_tipo_sconto == "1" ? "Discount" : "Voucher"}
            </span>
          </div>
        </div>
        <div className="row">
          {isActive ? (
            <>
              <div className="col-12 my-2">
                <span className="simple-dark-txt font-14 line-clamp line-clamp-2">
                  Attiva entro il:{" "}
                  <span className="d-block">
                    {" "}
                    {toStringFromDateTime(coupon.coupon_data_scadenza)}{" "}
                  </span>
                </span>
              </div>
              <div className="col-12">
                <a
                  //to={`/eshop/${coupon.eshop_domain}#codici-sconto`}
                  onClick={handleClick}
                  className="btn btn-custom-white w-100 mt-3 py-3 font-bolder"
                  title={coupon.eshop_domain}
                >
                  Attiva
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 text-start">
                <span className="border-top pt-3 w-100 d-block"> </span>
                <span className="rating-number-eshop font-bolder justify-content-left d-flex align-items-center">
                  <img
                    src={starOrange}
                    className="height-20 width-auto mr-2"
                    width="22px"
                    height="20px"
                    alt="Punteggio recensioni"
                    loading="lazy"
                  />
                  {coupon.eshop_rating.rating}{" "}
                </span>
              </div>
              <div className="col-12 text-start">
                <span className="d-block bolder-dark-title font-12">
                  {coupon.eshop_domain}{" "}
                </span>
                <span className="d-block lightgray-txt font-12">
                  Approfitta degli sconti!{" "}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
