import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import "../../assets/css/old/search-engine.css"
import codaBianca from "../../assets/images/coda-bianca.webp"
import { useAppDispatch } from "../../app/hooks"
import { domainsApi } from "../../app/services/esaScraper/domains"
import { productsApi } from "../../app/services/esaScraper/products"
import { ShopMenuItemChildren } from "./ShopMenuItemChildren"
import { ProductMenuItemChildren } from "./ProductMenuItemChildren"
import { EsaScraperResponse } from "../../models/esaScraper/esaScraperResponse.interface"
import { EsaScraperShop } from "../../models/esaScraper/esaScraperShop.interface"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { generalApi } from "../../app/services/esaScraper/general"
import searchEngineCss from "../../assets/css/old/search-engine.css?inline"

interface SearchEngineProps {
  isFixed: boolean
  hideLabel?: boolean
}

export const SearchEngine: React.FC<SearchEngineProps> = ({
  isFixed,
  hideLabel,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchType, setSearchType] = useState<"domains" | "products">(
    "domains",
  )
  const [searchText, setSearchText] = useState<string>("")
  const [timeoutId, setTimeoutId] = useState<any>(undefined)
  const [data, setData] = useState<
    EsaScraperResponse<EsaScraperShop | EsaScraperProduct> | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [dnsResult, setDnsResult] = useState<boolean>(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (searchText.length > 100) return

    const newText = e.target.value
    setSearchText(newText)

    clearTimeout(timeoutId)

    // Only debounce if input length is 3 or more
    if (newText.length >= 3) {
      const newTimeoutId = setTimeout(() => {
        fetchData(newText)
      }, 1000)

      setTimeoutId(newTimeoutId)
    } else {
      setData(undefined)
    }
  }

  const fetchData = async (queryString: string) => {
    setIsLoading(true)
    setDnsResult(false)
    const { data, isError } =
      searchType == "domains"
        ? await dispatch(
            domainsApi.endpoints.getSuggestedDomains.initiate({
              searchText: queryString.toLowerCase(),
            }),
          )
        : await dispatch(
            productsApi.endpoints.getSuggestedProducts.initiate({
              searchText: queryString.toLowerCase(),
            }),
          )

    if (isError) {
      setIsError(true)
    } else {
      if (data && data.hits.hits.length == 0) {
        if (searchType == "domains") {
          const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          let checkDns = domainRegex.test(queryString)
          if (checkDns) {
            const { data: dnsResult, isError } = await dispatch(
              generalApi.endpoints.checkDns.initiate({
                domain: queryString,
              }),
            )
            if (isError) {
              setIsError(true)
            }
            if (dnsResult == true) {
              setDnsResult(true)
            }
          }
        }
      }

      setData(data)
    }

    setIsLoading(false)
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (searchText.length >= 3) {
      if (searchType == "domains") {
        navigate(`search/eshopSearch/${searchText}`)
      } else {
        navigate(`search/prodotti/${searchText}`)
      }
      setData(undefined)
      // if (!selected || searchType == "products") {

      // } else if (selected.length > 0 && searchType == "domains") {
      //   const selectedOption = selected[0] as Hit<EsaScraperShop>
      //   navigate(`eshop/${selectedOption._source.domain}`)
      // }
    }
  }

  useEffect(() => {
    if (searchText.length >= 3) fetchData(searchText)
  }, [searchType])

  useEffect(() => {
    setSearchText("")
    setData(undefined)
  }, [location])

  return (
    <div
      className={`searchHeaderEngine pb-3 ${
        isFixed ? "search-engine-fixed" : ""
      }${hideLabel ? "pt-3" : ""}`}
    >
      <div
        className={`mx-auto ${isFixed ? "container" : ""}`}
        style={{ overflow: "visible" }}
      >
        <div className="row">
          <div id="search-engine-container" className="col-12">
            <form className="search-engine-container" onSubmit={handleSubmit}>
              {!hideLabel && (
                <div className="searchEngineSelectors row px-3 pt-3 hide">
                  <label
                    className={`col-auto radio-select badge badge-selectable px-4 py-2 me-1 me-lg-3 ${
                      searchType === "domains" ? "active" : ""
                    }`}
                    rel="searchContainer"
                  >
                    eCommerce
                    <input
                      className="search-type-control"
                      type="radio"
                      name="eshop"
                      checked={searchType === "domains"}
                      onChange={() => {
                        setSearchType("domains")
                        setData(undefined)
                      }}
                    />
                    <span className="custom-radio"></span>
                  </label>

                  <label
                    className={`col-auto radio-select badge badge-selectable px-4 py-2 me-1 me-lg-3 ${
                      searchType === "products" ? "active" : ""
                    }`}
                    rel="searchContainer"
                  >
                    Prodotti
                    <input
                      className="search-type-control"
                      type="radio"
                      name="products"
                      checked={searchType === "products"}
                      onChange={() => {
                        setSearchType("products")
                        setData(undefined)
                      }}
                    />
                    <span className="custom-radio"></span>
                  </label>
                </div>
              )}

              <input
                type="text"
                id="esa-search"
                autoComplete="off"
                className="w-100 py-3 px-3 search-engine typeahead"
                name="esa-search"
                placeholder={
                  searchType === "domains"
                    ? "Cerca eCommerce"
                    : "Cerca Prodotti"
                }
                value={searchText}
                onChange={handleInputChange}
              />

              <ul
                className="typeahead dropdown-menu"
                style={data ? { left: "0px", display: "block" } : {}}
              >
                {data && data.hits.hits.length == 0 && (
                  <>
                    {searchType == "domains" ? (
                      !dnsResult && (
                        <>
                          <li className="d-block text-center errorMsg text-center w-100">
                            <span className="first-link active">
                              <span className="font-14 xs-font-12 blue-color">
                                <FontAwesomeIcon
                                  icon={["far", "question-circle"]}
                                  className="search-icon"
                                />{" "}
                                Non trovi un ecommerce? Scrivilo correttamente.
                                (es. <b>ecommerce.it</b>)
                              </span>
                            </span>
                          </li>
                          <li className="errorMsg text-center w-100 d-block">
                            <span className="first-link">
                              <span className="red-color font-14 font-bold text-center d-block mt-3">
                                <FontAwesomeIcon
                                  icon={["fas", "exclamation-triangle"]}
                                  className="search-icon"
                                />{" "}
                                Nessun ecommerce trovato!
                              </span>
                            </span>
                          </li>
                        </>
                      )
                    ) : (
                      <li className="prod-search-item no-suggestions">
                        <Link
                          className="first-link active"
                          to={`/search/eshopSearch/${searchText}`}
                        >
                          <span className="eshop-search">
                            <span className="s-res">
                              Stai cercando un eCommerce? <u>Clicca qui</u>
                            </span>
                          </span>
                        </Link>
                      </li>
                    )}
                  </>
                )}
                {data &&
                  data.hits.hits.length > 0 &&
                  data.hits.hits.slice(0, 5).map((result, index) => {
                    if (searchType == "domains") {
                      return (
                        <ShopMenuItemChildren
                          shop={result._source as EsaScraperShop}
                          searchText={searchText}
                        />
                      )
                    } else {
                      return (
                        <ProductMenuItemChildren
                          product={result._source as EsaScraperProduct}
                          searchText={searchText}
                        />
                      )
                    }
                  })}
                {data && data.hits.hits.length > 5 && (
                  <li className="d-flex justify-content-center w-100 errorMsg">
                    <Link
                      className="first-link w-100"
                      to={
                        searchType === "domains"
                          ? `/search/eshopSearch/${searchText}`
                          : `/search/prodotti/${searchText}`
                      }
                    >
                      <span className="text-center d-block underline orange-color font-14 font-semibold mt-2">
                        Vedi tutti i {data.hits.total.value} risultati per "
                        <strong>{searchText}</strong>"
                      </span>
                    </Link>
                  </li>
                )}
                {searchType == "domains" && dnsResult && (
                  <li className="errorMsg d-block text-center">
                    <Link
                      className="first-link"
                      to={`/invia-recensione?e=${searchText}`}
                    >
                      <div className="d-block text-center w-100">
                        <span className="d-block font-14 xs-font-12 blue-color">
                          <i className="fa fa-exclamation-triangle search-icon"></i>
                          Nessuna recensione presente su questo eCommerce
                        </span>
                        <span className="btn btn-blur-orange mt-3">
                          Scrivi tu la prima recensione{" "}
                          <i className="fa fa-arrow-right"> </i>
                        </span>
                      </div>
                    </Link>
                  </li>
                )}
              </ul>
              {searchText.length > 0 && (
                <a
                  id="reset-search"
                  className={`reset-search active cursor-pointer ${
                    hideLabel ? "reset-search-alt" : ""
                  }`}
                  onClick={() => {
                    setSearchText("")
                    setData(undefined)
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "times"]} />
                </a>
              )}
              <button
                className="btn btn-gradient-orange search-engine-btn float-right justify-content-center align-items-center"
                type="submit"
                // disabled={state.text.length > 0 ? false : true}
                style={{ zIndex: 3 }}
                // onClick={() => handleChange()}
              >
                {isLoading ? (
                  <img
                    className="wait-res search-engine-loader"
                    src={codaBianca}
                  />
                ) : (
                  <FontAwesomeIcon icon={["fas", "magnifying-glass"]} />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <style>{searchEngineCss}</style>
    </div>
  )
}
