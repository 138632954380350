import React from "react"
import imgSection1 from "../../assets/images/coupon_info/img-section-1.png"
import imgSection2 from "../../assets/images/coupon_info/img-section-2.png"
import imgSection3 from "../../assets/images/coupon_info/img-section-3.png"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCouponModal } from "../../features/modals/modalsSlice"
import OwlCarousel from "react-owl-carousel"
import {
  useGetCouponsQuery,
  useGetCouponsUserHistoryQuery,
} from "../../app/services/base/coupons"
import couponInfoCss from "./couponInfo.css?inline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CouponTicket } from "../../features/coupon_ticket/CouponTicket"
import { CouponModal } from "../../features/modals/CouponModal"
import { selectCurrentUser } from "../../features/auth/authSlice"
import { skipToken } from "@reduxjs/toolkit/query"
import { Helmet } from "react-helmet"

export const CouponInfo: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectCurrentUser)
  const couponModal = useAppSelector(selectCouponModal)
  const { data: couponsWithShops } = useGetCouponsQuery({
    page: 1,
    perPage: 10,
  })

  const { data: unlockedCoupons } = useGetCouponsUserHistoryQuery(
    user
      ? {
          userId: user.id,
        }
      : skipToken,
  )

  return (
    <>
      <Helmet>
        <title>
          Come guadagnare codici sconto da spendere nei negozi online
        </title>
        <meta
          name="description"
          content="Grazie alle recensioni che scrivi su eShoppingAdvisor puoi sbloccare i coupon sconto messi a disposizione dagli e-commerce registrati."
        />
        <link rel="canonical" type="" href={window.location.href} />
      </Helmet>
      <section className="info-coupon-page">
        <div className="container">
          <h1 className="info-coupon-title">
            {" "}
            Come guadagnare codici sconto da spendere nei negozi online
          </h1>
        </div>

        <div className="info-copuon-section info-section-1">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img className="img-fluid info-img" src={imgSection1} />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3 className="info-coupon-section-title">
                  {" "}
                  Recensisci e sblocca i coupon{" "}
                </h3>
                <span className="info-coupon-txt">
                  {" "}
                  Grazie alle recensioni che scrivi su eShoppingAdvisor potrai
                  sbloccare i coupon sconto messi a disposizione dagli
                  e-commerce registrati e diventare un micro-influencer sulla
                  community e sul web, scalando la “
                  <Link to="/classifica-recensori" target="_blank">
                    Classifica recensori
                  </Link>
                  ”.{" "}
                </span>
                <span className="info-coupon-txt">
                  {" "}
                  Ogni volta che scrivi una recensione accumuli infatti dei
                  punti che potrai utilizzare per{" "}
                  <Link to="/coupon/ultimi-coupon" target="_blank">
                    scaricare i Coupon Sconto
                  </Link>
                  .{" "}
                </span>
                <Link
                  to="/scrivi-recensione"
                  className="info-coupon-cta btn btn-cta"
                >
                  {" "}
                  Scrivi una recensione{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="info-copuon-section section-blue-back text-center">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h2 className="info-coupon-txt">
                  {" "}
                  <p
                  // className="info-coupon-section-title"
                  >
                    {" "}
                    <b>I Coupon di eShoppingAdvisor</b> sono sconti sui prodotti
                    messi a disposizione dagli eCommerce registrati sul nostro
                    portale.{" "}
                  </p>{" "}
                </h2>
                <span className="info-coupon-txt">
                  {" "}
                  <p
                  // className="info-coupon-section-title"
                  >
                    {" "}
                    Puoi accedere ai Coupon sconto acquistando i prodotti degli
                    eCommerce registrati sul portale di eShoppingAdvisor e
                    scrivendo una recensione riguardo la tua esperienza di
                    acquisto.{" "}
                  </p>{" "}
                </span>
                <span className="info-coupon-txt">
                  {" "}
                  <p
                  // className="info-coupon-section-title"
                  >
                    {" "}
                    Ogni volta che scrivi una recensione accumuli dei punti che
                    potrai usare per scaricare i Coupon sconto.{" "}
                  </p>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="info-copuon-section info-section-2">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-2">
                <img className="img-fluid info-img" src={imgSection2} />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-1">
                <h3 className="info-coupon-section-title"> Come funziona? </h3>
                <span className="info-coupon-txt">
                  {" "}
                  Per ogni recensione verificata accumuli{" "}
                  <span className="info_bold"> 0,25 punti.</span> <br /> Per
                  ogni recensione verificata accumuli{" "}
                  <span className="info_bold"> 1,00 punto.</span>{" "}
                </span>
                <span className="info-coupon-txt">
                  {" "}
                  Le recensioni vengono <b> verificate </b> quando, dopo averla
                  scritta, carichi una prova di acquisto (fattura, ricevuta,
                  conferma d’ordine, foto del prodotto, video ecc.) oppure
                  quando la scrivi a seguito di un invito (via email o sms o
                  WhatsApp) da parte dell’e-commerce nel quale hai fatto
                  l’acquisto.{" "}
                </span>
                <span className="info-coupon-txt">
                  {" "}
                  In tutti gli altri casi parliamo di una recensione{" "}
                  <b> verificata </b> del valore di 0,25 punti.{" "}
                </span>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 order-md-3">
                <span className="info-coupon-txt">
                  Una recensione può essere validata e quindi pubblicata sulla
                  vetrina dell’e-commerce recensito solo se rispetta i seguenti
                  requisiti:
                  <ul className="text-left my-3">
                    <li className="my-1">
                      {" "}
                      La recensione deve descrivere una vera e propria
                      esperienza d’acquisto;{" "}
                    </li>
                    <li className="my-1">
                      {" "}
                      La recensione non può contenere parole offensive e
                      diffamatorie, le quali potrebbero essere censurate o
                      determinare la mancata pubblicazione della recensione
                      stessa;{" "}
                    </li>
                    <li className="my-1">
                      {" "}
                      La recensione potrà essere verificata solo se la prova
                      caricata attesterà l’effettivo acquisto (fattura,
                      ricevuta, conferma d’ordine, ecc.).{" "}
                    </li>
                  </ul>
                </span>
                <div className="text-center">
                  <Link
                    to="/scrivi-recensione"
                    className="info-coupon-cta btn btn-cta"
                  >
                    {" "}
                    Inizia ad accumulare punti{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="info-copuon-section info-section-3">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img className="img-fluid info-img" src={imgSection3} />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <h3 className="info-coupon-section-title">
                  {" "}
                  Dietro una recensione c’è molto più di un codice sconto…
                  scrivere recensioni conviene a tutti!{" "}
                </h3>
                <span className="info-coupon-txt">
                  {" "}
                  Con i codici sconto eShoppingAdvisor gli eCommerce rendono più
                  felici i propri clienti, mentre gli utenti acquistano prodotti
                  a prezzi unici sul mercato, e scalano la{" "}
                  <Link to="/classifica-recensori" target="_blank">
                    {" "}
                    classifica della nostra community
                  </Link>
                  . Attraverso le recensioni verificate sarà possibile
                  contribuire allo sviluppo di un mercato equo, sicuro e
                  sostenibile, che offre le stesse opportunità a chiunque e
                  valorizza le realtà più meritevoli.{" "}
                </span>
                <span className="info-coupon-txt">
                  {" "}
                  <b>
                    {" "}
                    Vuoi essere l'artefice, insieme a noi, di questa
                    rivoluzione?&nbsp;
                  </b>{" "}
                </span>
                <Link
                  to="/scrivi-recensione"
                  className="info-coupon-cta btn btn-cta"
                >
                  {" "}
                  Inizia subito{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {couponsWithShops && (
          <div className="coupon-carousel-container">
            <div className="ultimi-coupon">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                    <h2 className="title">Ultimi coupon caricati </h2>
                  </div>
                </div>
                <div className="lista-ultimi-coupon">
                  <OwlCarousel
                    id="last-coupons"
                    className="owl-theme"
                    dots={false}
                    loop={true}
                    dotsEach={3}
                    autoplay={true}
                    autoplayTimeout={4500}
                    autoplayHoverPause={true}
                    autoplaySpeed={650}
                    nav={false}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      768: {
                        items: 1.2,
                      },
                      1220: { items: 1.6 },
                    }}
                  >
                    {couponsWithShops.items.map((coupon) => (
                      <ul className="list-unstyled p-1">
                        <CouponTicket
                          key={coupon.coupon.id}
                          couponWithShop={coupon}
                          onlyInfo={false}
                          itemCustomClass="coupon-border-bg-white coupon-blue-ticket"
                          isUnlocked={unlockedCoupons?.purchases.some(
                            (purchase) => purchase.id == coupon.coupon.id,
                          )}
                        />
                      </ul>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            {couponModal && <CouponModal />}
            <div className="info-coupon-cta">
              <Link to="/coupon/ultimi-coupon" className="btn coupon_link">
                {" "}
                Mostra tutti i coupon{" "}
                <FontAwesomeIcon icon={["fas", "chevron-right"]} />{" "}
              </Link>
            </div>
          </div>
        )}

        <div className="info-copuon-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <span className="info-coupon-txt">
                  {" "}
                  <p
                  // className="info-coupon-section-title"
                  >
                    {" "}
                    Ogni recensione che fai diventerà una leva decisionale per
                    chiunque interagirà con l’eCommerce dopo di te.
                    <br /> Il nostro obiettivo, come quello di ogni nostro
                    utente, è quello di portare in alto gli eCommerce più
                    meritevoli, creando un portale meritocratico e sicuro.{" "}
                  </p>{" "}
                </span>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <span className="info-coupon-txt">
                  {" "}
                  <p
                  // className="info-coupon-section-title"
                  >
                    {" "}
                    Ricorda: gli sconti messi a disposizione dagli eCommerce
                    registrati sul portale eshoppingadvisor.com seguono le
                    rispettive strategie aziendali. eShoppingAdvisor non si
                    assume alcuna responsabilità in merito alla mancata
                    applicazione delle condizioni oggetto dei coupon.{" "}
                  </p>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>

        <style>{couponInfoCss}</style>
      </section>
    </>
  )
}
