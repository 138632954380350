import { SearchFilterOption } from "../models/base/searchFilter.interface"
import { ElasticQuery } from "../models/esaScraper/elasticQuery.interface"

export const buildElasticQuery = (
  searchType: "Products" | "Shops",
  filters: Record<string, SearchFilterOption>,
  searchAfter?: number[],
  aggregation?: "domain" | "brand",
): ElasticQuery => {
  let elasticQuery: any = {
    query: {
      bool: {
        filter: [],
      },
    },
    size: searchType == "Products" ? 30 : 20,
  }

  switch (searchType) {
    case "Products":
      if (aggregation == "domain") {
        elasticQuery.domain_aggregation = true
      } else if (aggregation == "brand") {
        elasticQuery.brand_aggregation = true
      }

      // Filtro per scheda prodotto
      // if (filters.link_domain_id && filters.link_domain_id.value) {
      //   elasticQuery.query.bool.filter.push({
      //     term: {
      //       link_domain_id: filters.link_domain_id.value,
      //     },
      //   })
      // }

      if (
        filters.brands &&
        Array.isArray(filters.brands.value) &&
        filters.brands.value.length > 0
      ) {
        elasticQuery.brand = filters.brands.value.map((key) => key)
      }

      if (
        filters.shops &&
        Array.isArray(filters.shops.value) &&
        filters.shops.value.length > 0
      ) {
        elasticQuery.query.bool.filter.push({
          terms: {
            domain: filters.shops.value.map((key) => key),
          },
        })
      }

      if (filters.includeAdultProducts && filters.includeAdultProducts.value) {
        // elasticQuery.query.bool.filter.push({
        //   term: {
        //     adult_flag: true,
        //   },
        // })
      }

      if (
        filters.onlySuggestedProducts &&
        filters.onlySuggestedProducts.value
      ) {
        elasticQuery.query.bool.filter.push(
          {
            range: {
              plan: {
                gte: 5,
              },
            },
          },
          {
            exists: {
              field: "plan",
            },
          },
        )
      }

      if (filters.eShopDomain && filters.eShopDomain.value) {
        elasticQuery.query.bool.filter.push({
          term: {"domain.keyword": filters.eShopDomain.value},
        })
      }

      if (filters.categorySlug && filters.categorySlug.value) {
        elasticQuery.product_category = filters.categorySlug.value
      }

      break

    case "Shops":
      if (filters.eShopDomain && filters.eShopDomain.value) {
        elasticQuery.query.bool.filter.push({
          term: {
            domain: filters.eShopDomain.value,
          },
        })
      }

      if (filters.enrichData && filters.enrichData.value) {
        elasticQuery.enrich_data = filters.enrichData.value
      }

      break
    default:
      break
  }

  if (filters.orderBy && filters.orderBy.value != "") {
    var dynamicKey = filters.orderBy.value.toString().split("-")[0]
    const order = filters.orderBy.value.toString().split("-")[1]
    if (dynamicKey == "domain") {
      elasticQuery.sort = [{ "domain.keyword": { order: order } }]
    } else {
      elasticQuery.sort = [
        {
          [dynamicKey]: order,
        },
      ]
    }
  }

  if (filters.rating && filters.rating.value != "0") {
    elasticQuery.query.bool.filter.push(
      {
        range: {
          score: {
            gte: Number(filters.rating.value),
            lte: 5,
          },
        },
      },
      {
        exists: {
          field: "score",
        },
      },
    )
  }

  if (filters.minNumberOfReviews && filters.minNumberOfReviews.value != "0") {
    elasticQuery.query.bool.filter.push(
      {
        range: {
          reviews: {
            gte: Number(filters.minNumberOfReviews.value),
          },
        },
      },
      {
        exists: {
          field: "reviews",
        },
      },
    )
  }

  if (
    filters.minNumberOfLatestReviews &&
    filters.minNumberOfLatestReviews.value != "0"
  ) {
    elasticQuery.query.bool.filter.push(
      {
        range: {
          reviews_30: {
            gte: Number(filters.minNumberOfLatestReviews.value),
          },
        },
      },
      {
        exists: {
          field: "reviews_30",
        },
      },
    )
  }

  if (filters.onlyVerifiedReviews && filters.onlyVerifiedReviews.value) {
    elasticQuery.query.bool.filter.push(
      {
        range: {
          reviews_cert: {
            gte: 1,
          },
        },
      },
      {
        exists: {
          field: "reviews_cert",
        },
      },
    )
  }

  if (searchAfter != undefined) {
    elasticQuery.search_after = searchAfter
  }

  elasticQuery.enrich_data = true

  return elasticQuery
}

export const buildElasticQueryForReviews = (
  filters: Record<
    string,
    string | number | boolean | string[] | number[] | undefined
  >,
  searchAfter?: number[],
): ElasticQuery => {
  let elasticQuery: any = {
    query: {
      bool: {
        filter: [],
      },
    },
    size: filters.size ? filters.size : 20,
  }

  if (filters.reviews as string[]) {
    elasticQuery.query.bool.filter.push({
      terms: {
        review_esa_id: filters.reviews,
      },
    })
  }

  if (filters.import_source as string[]) {
    elasticQuery.query.bool.filter.push({
      term: {
        import_source: filters.import_source,
      },
    })
  }

  if (filters.esa_user_id as number) {
    elasticQuery.esa_user_id = filters.esa_user_id
  }

  if (filters.orderBy && filters.orderBy != "") {
    const dynamicKey = filters.orderBy.toString().split("-")[0]
    const order = filters.orderBy.toString().split("-")[1]
    elasticQuery.sort = [
      {
        [dynamicKey]: order,
      },
    ]
  }

  if (filters.ratings && (filters.ratings as number[]).length > 0) {
    elasticQuery.query.bool.filter.push({
      bool: {
        should: (filters.ratings as number[]).map((rating) => ({
          range: {
            review_rating: {
              gte: rating,
              lte: rating != 5 ? rating + 0.99 : rating,
            },
          },
        })),
      },
    })
    elasticQuery.query.bool.filter.push({
      exists: {
        field: "review_rating",
      },
    })
  }

  if (filters.enrichData) {
    elasticQuery.enrich_data = true
  }

  if (searchAfter != undefined) {
    elasticQuery.search_after = searchAfter
  }

  return elasticQuery
}
