import { CreateUserResponse } from "../../../models/base/createUserResponse.interface"
import { LostPassRequest } from "../../../models/base/lostPassRequest.interface"
import { User } from "../../../models/base/user.interface"
import { baseApi } from "./baseApi"
import { UserProfile } from "../../../models/base/userProfile.interface"
import { UpdateUserResponse } from "../../../models/base/updateUserResponse.interface"

export const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    login: build.mutation<
      User,
      {
        email?: string
        password?: string
        type?: "facebook" | "google"
        user_id?: number
      }
    >({
      query: ({ email, password, type, user_id }) => {
        const queryParams = new URLSearchParams()

        email && queryParams.append("email", email)
        password && queryParams.append("password", password)
        type && queryParams.append("type", type)
        user_id !== undefined &&
          user_id !== null &&
          queryParams.append("user_id", user_id.toString())

        return {
          url: "consumer-users/auth/login",
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: queryParams,
        }
      },
      invalidatesTags: ["User"],
    }),
    // MAPPATO
    createUser: build.mutation<
      CreateUserResponse,
      {
        email: string
        name: string
        password?: string
        verifyPassword?: string
        type?: "facebook" | "google" | "guest"
        user_id?: string
        surname?: string
        image_url?: string
      }
    >({
      query: (data) => ({
        url: "consumer-users/auth/signup",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    // MAPPATO
    recoverPassword: build.mutation<
      {
        success?: string
      },
      LostPassRequest
    >({
      query: ({ email }) => ({
        url: "consumer-users/auth/recover",
        method: "GET",
        params: { email },
      }),
    }),
    // MAPPATO
    getProfile: build.query<UserProfile, string| number | undefined>({
      query: (userId) =>
        userId ? { url: `frontend/user/${userId}` } : { url: `frontend/user` },
      providesTags: (result, error, id) => ["User"],
    }),
    // MAPPATO
    updateProfile: build.mutation<UpdateUserResponse, Partial<UserProfile>>({
      query: (body) => {
        body.name = body.firstname
        body.surname = body.lastname
        delete body.firstname
        delete body.lastname

        return {
          url: `consumer-users/update/${body.id}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["User"],
    }),
    // MAPPATO
    deleteAccount: build.mutation<any, void>({
      query: () => ({
        url: "consumer-users/auth/delete",
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
    // MAPPATO
    uploadUserProfilePic: build.mutation<{ success: string }, FormData>({
      query: (body) => ({
        url: `consumer-users/frontend/upload-profile-pic`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    // MAPPATO
    checkOptin: build.mutation<User, string>({
      query: (token) => ({
        url: `/consumer-users/auth/optin?token=${token}`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useCreateUserMutation,
  useRecoverPasswordMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useDeleteAccountMutation,
  useUploadUserProfilePicMutation,
  useCheckOptinMutation,
} = usersApi
