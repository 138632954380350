import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SimpleUser } from "../../../models/base/simpleUser.interface"
import { Review } from "../../../models/base/review.interface"
import { Shop } from "../../../models/base/shop.interface"
import { getShopLogo } from "../../../utils/shopUtils"
import shopPlaceholderLogo from "../../../assets/images/thumb_placeholder.png"
import { RatingStars } from "../../../features/rating_stars/RatingStars"
import { toStringFromDateTimeWithSlash } from "../../../utils/stringUtils"

interface LatestReviewProps {
  review: Review
  simpleUser: SimpleUser
  shop: Shop
}

export const LatestReview: React.FC<LatestReviewProps> = ({
  review,
  simpleUser,
  shop,
}) => {
  return shop ? (
    <div className="item card background-transparent border-radius-75 p-3 me-0 me-md-2">
      <div className="row align-items-center py-2">
        <div className="col-6 text-start">
          <span className="gray-text font-bolder font-23 text-overflow-ellipis">
            {" "}
            {simpleUser.name}{" "}
          </span>
        </div>
        <div className="col-6 text-right">
          <span className="gray-text text-overflow-ellipis">
            {" "}
            {toStringFromDateTimeWithSlash(review.insert_date)}{" "}
          </span>
        </div>
      </div>
      <div className="py-2">
        <RatingStars
          rating={Number(review.overall_rating)}
          showAverageRating={false}
          size="big"
        />
      </div>
      <div className="py-2 height-240">
        <Link
          to={`/reviews/${review.id}`}
          className="simple-white-link line-clamp line-clamp-2 bolder-dark-title mb-3"
        >
          {review.title}
        </Link>
        <div className="">
          <span className="line-clamp line-clamp-5 gray-text font-normal">
            {" "}
            {review.description}{" "}
          </span>
          <span className="d-block position-absolute mt-2 gray-text font-14">
            {" "}
            <FontAwesomeIcon icon={["fas", "check"]} /> Verificata{" "}
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-center align-content-center text-center pt-4">
        <Link to={`/eshop/${shop.domain}`} title={shop.domain}>
          <img
            src={getShopLogo(shop.id)}
            className="img-fluid filter-grayscale height-50 width-auto"
            style={{ height: "50px" }}
            alt={shop.domain}
            onError={(e) => (e.currentTarget.src = shopPlaceholderLogo)}
          />
        </Link>
      </div>
    </div>
  ) : (
    false
  )
}
