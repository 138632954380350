import React from "react"
import { useParams } from "react-router-dom"
import { ReviewContainer } from "../../features/eshop/reviews/ReviewContainer"
import { useGetReviewQuery } from "../../app/services/base/reviews"
import useWindowSize from "../../utils/useWindowSize"
import { useAppSelector } from "../../app/hooks"
import {
  selectReportModal,
  selectShareModal,
} from "../../features/modals/modalsSlice"
import { ReportModal } from "../../features/modals/ReportModal"
import { useGetSearchDomainsQuery } from "../../app/services/esaScraper/domains"
import { skipToken } from "@reduxjs/toolkit/query"
import { AppBreadcrumb } from "../../features/app_breadcrumb/AppBreadcrumb"
import { EShopDisclaimer } from "../../features/eshop/disclaimer/EShopDisclaimer"
import { ShareModal } from "../../features/modals/ShareModal"
import { selectCurrentUser } from "../../features/auth/authSlice"
import { Helmet } from "react-helmet"

export const ReviewDetails: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const { reviewId } = useParams()
  const shareModal = useAppSelector(selectShareModal)
  const reportModal = useAppSelector(selectReportModal)

  const { data: reviewShopUserUnion } = useGetReviewQuery(reviewId!)

  const { scraperShop } = useGetSearchDomainsQuery(
    reviewShopUserUnion?.eshop
      ? {
          filters: {
            eShopDomain: {
              name: "",
              label: "",
              value: reviewShopUserUnion.eshop.domain,
            },
            enrichData: { name: "", label: "", value: true },
            // size: {name:"", label:"", value:1},
          },
          searchAfter: undefined,
        }
      : skipToken,
    {
      selectFromResult: ({ data }) => ({
        scraperShop: data?.hits.hits.find(
          (shop) => shop._source.domain === reviewShopUserUnion?.eshop.domain,
        ),
      }),
    },
  )

  const windowSize = useWindowSize()
  const isMobile = windowSize.width && windowSize.width <= 768

  if (!reviewShopUserUnion) {
    return <></>
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" type="" href={window.location.href} />
      </Helmet>
      <div className="container container-custom">
        <AppBreadcrumb
          routes={
            scraperShop &&
            scraperShop._source.esa_categories &&
            scraperShop._source.esa_categories.length > 0
              ? [
                  { name: "Home", slug: "/" },
                  {
                    name: scraperShop._source.esa_categories[0].name,
                    slug: `/categorie/${scraperShop._source.esa_categories[0].slug}`,
                  },
                  {
                    name: reviewShopUserUnion.eshop.domain,
                    slug: `/eshop/${reviewShopUserUnion.eshop.domain}`,
                  },
                  {
                    name: `La recensione di ${reviewShopUserUnion.user.name}`,
                    slug: "",
                  },
                ]
              : [
                  { name: "Home", slug: "/" },
                  {
                    name: reviewShopUserUnion.eshop.domain,
                    slug: `/eshop/${reviewShopUserUnion.eshop.domain}`,
                  },
                  {
                    name: `La recensione di ${reviewShopUserUnion.user.name}`,
                    slug: "",
                  },
                ]
          }
        />
        <div className="eshop-grid-container mt-3">
          <div className="eshop-details-container">
            <main id="main" className="d-flex flex-column">
              <div className="d-flex flex-column">
                <div className="card w-100 h-100 mb-4">
                  <div className="card-body d-flex flex-column p-4">
                    <div className="list-group list-group-flush">
                      <ReviewContainer
                        shop={reviewShopUserUnion.eshop}
                        review={reviewShopUserUnion.review}
                        simpleUser={reviewShopUserUnion.user}
                        disableReviewLink
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div className="eshop-cards-container">
            <EShopDisclaimer
              shop={reviewShopUserUnion.eshop}
              isMobile={isMobile}
              isReviewDetails={true}
            />
          </div>
          <div className="eshop-tabs-container"></div>
        </div>
      </div>
      {shareModal && <ShareModal />}
      {reportModal && <ReportModal />}
    </>
  )
}
