import { Link } from "react-router-dom"
import { Category } from "../../../models/base/category.interface"

interface RecommendedCategoryProps {
  index: number
  category: Category
  hoveredIndex: number | null
  onHover: (index: number) => void
}

export const RecommendedCategory: React.FC<RecommendedCategoryProps> = ({
  index,
  category: { name, slug, imageUrl, suggestedDomains, details },
  hoveredIndex,
  onHover,
}) => {
  if (!details) {
    return null
  }
  return (
    <div
      className={`d-block d-xl-inline-flex categoriesHomeCard cursor-pointer px-1 my-3 my-lg-0 text-decoration-none ${
        hoveredIndex === index ? "card-primary-focus" : "card-secondary-focus"
      }`}
      title={suggestedDomains && suggestedDomains[0].domain}
      onMouseEnter={() => onHover(index)}
    >
      <div
        className="categoriesHomeCardBackgound w-100 h-100 card rounded"
        style={{
          background: `url(${
            details.background ? details.background : imageUrl
          })`,
        }}
      >
        <div className="containerGradientBackdrop w-100 h-100">
          <div className="py-2 px-3">
            <Link
              to={`/categorie/${slug}`}
              className="bolder-white-title bold-white-link text-overflow-ellipis"
              title={name}
            >
              {name}
            </Link>
          </div>
          <div className="border-bold-bottom"></div>
          <div className="row align-items-end position-bottom py-2 px-3">
            <div className="col-12">
              <span className="simple-white-txt d-block">
                {" "}
                La nostra scelta{" "}
              </span>
            </div>
            <div className="col-12">
              <Link
                to={`/eshop/${suggestedDomains && suggestedDomains[0].domain}`}
                className="d-block bold-white-link bolder-white-title text-overflow-ellipis"
                title={suggestedDomains && suggestedDomains[0].domain}
              >
                {suggestedDomains && suggestedDomains[0].domain}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
