import React, { useState } from "react"
import { Shop } from "../../../models/base/shop.interface"

interface EShopInfoCardProps {
  shop: Shop
}

export const EShopInfoCard: React.FC<EShopInfoCardProps> = ({ shop }) => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const flagSrc = `${import.meta.env.BASE_URL}src/assets/images/flags-mini/${
    shop.eshopData.showcaseData.nation_id || ""
  }.png`

  return (
    <div
      className={`card eshop-custom-card mb-0 mb-lg-2 me-2 me-lg-0 ${
        showAll ? "mobile-height-auto" : ""
      }`}
    >
      <div className="card-body d-flex flex-column justify-content-between px-3 py-4 p-lg-4">
        <div>
          <h5 className="blue-card-title m-0 mb-2 mb-lg-3">Info e Contatti</h5>
          <hr className="border border-dark m-0 mb-lg-3 d-none d-lg-block" />
          <h6 className="lightgray-txt font-14 m-0 mb-1">Email</h6>
          <p className="dark-color font-semi-bolder font-14 m-0 mb-3">
            {shop.plan != "ibrido" ? shop.eshopData.showcaseData.email : "--"}
          </p>
          <h6 className="lightgray-txt font-14 m-0 mb-1">Assistenza Clienti</h6>
          <p className="dark-color font-semi-bolder font-14 m-0 mb-3">
            {shop.plan != "ibrido" ? shop.eshopData.showcaseData?.phone : "--"}
          </p>
          <h6 className="lightgray-txt font-14 m-0 mb-1">Ragione sociale</h6>
          <p className="dark-color font-semi-bolder font-14 m-0 mb-3">
            {shop.eshopData.showcaseData?.business_name ? (
              <>
                {shop.eshopData.showcaseData?.business_name}
                <img
                  className="flag-eshop mx-1"
                  src={flagSrc}
                  width="22"
                  height="15"
                  loading="lazy"
                  alt={shop.eshopData.showcaseData.nation_id}
                />
              </>
            ) : (
              "--"
            )}
          </p>
          <div className={`d-lg-block ${showAll ? "d-block" : "d-none"}`}>
            <h6 className="lightgray-txt font-14 m-0 mb-1">Indirizzo</h6>
            <p className="dark-color font-semi-bolder font-14 m-0 mb-3">
              {shop.plan != "ibrido"
                ? shop.eshopData.showcaseData.address
                : "--"}
            </p>
            <h6 className="lightgray-txt font-14 m-0 mb-1">
              Metodi di pagamento accettati
            </h6>
            <div className="d-flex flex-row flex-wrap">
              <div className="d-flex flex-row flex-wrap">
                {shop.plan != "ibrido" &&
                shop.eshopData.formattedPaymentMethods?.length > 0
                  ? shop.eshopData.formattedPaymentMethods?.map((method) => (
                      <div key={method.title.name} className="pb-3 me-4">
                        <img
                          src={
                            import.meta.env.VITE_CDN_BASE_URL +
                            "/assets/paymentsLogo/" +
                            method.image
                          }
                          alt={method.title.name}
                        />
                      </div>
                    ))
                  : "--"}
              </div>
            </div>
          </div>
        </div>
        {!showAll && (
          <div className="d-lg-none text-center">
            <a className="simple-blue-link" onClick={() => setShowAll(true)}>
              Visualizza tutto
            </a>
          </div>
        )}
      </div>
    </div>
  )
}
