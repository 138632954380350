import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { InvitationOrderProduct } from "../../models/base/invitationOrder.interface"

export interface ProductReviewFormData {
  score: number
  description: string
  img_prod: string
  name: string
}

export interface ShopReviewFormData {
  scores: Record<string, number>
  title: string
  description: string
  isAnonymous: boolean
  images: any[]
  video?: any
  videoUrl: string
}

export interface SendReviewFormData {
  shopId?: number
  shop: ShopReviewFormData
  products: Record<string, ProductReviewFormData>
  confirmedProducts: Record<string, ProductReviewFormData>
  errors: Record<string, string>
}

export interface SendReviewState {
  isProductReview: boolean
  formData: SendReviewFormData
}

const initialState: SendReviewState = {
  isProductReview: false,
  formData: {
    shopId: undefined,
    shop: {
      scores: {
        "Chiarezza sito web": 0,
        "Valutazione prodotto/servizio": 0,
        "Servizio di pagamento": 0,
        "Consegna/Reso": 0,
        "Assistenza clienti": 0,
        "Lo consiglieresti a qualcuno?": 0,
      },
      title: "",
      description: "",
      isAnonymous: false,
      images: [],
      video: undefined,
      videoUrl: "",
    },
    products: {},
    confirmedProducts: {},
    errors: {},
  },
}

export const sendReviewSlice = createSlice({
  name: "sendReview",
  initialState,
  reducers: {
    setShopId: (state, action: PayloadAction<string>) => {
      state.formData.shopId = Number(action.payload)
    },
    updateShopScores: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      const { name, value } = action.payload
      state.formData.shop.scores[name] = Number(value)
    },
    updateShopReview: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      const { name, value } = action.payload
      if (name == "title") {
        state.formData.shop.title = value
      } else {
        state.formData.shop.description = value
      }
    },
    toggleShopIsAnonymous: (state) => {
      state.formData.shop.isAnonymous = !state.formData.shop.isAnonymous
    },
    updateImages: (state, action: PayloadAction<any[]>) => {
      state.formData.shop.images = action.payload
    },
    updateVideo: (state, action: PayloadAction<any>) => {
      state.formData.shop.video = action.payload
    },
    updateVideoUrl: (state, action: PayloadAction<string>) => {
      state.formData.shop.videoUrl = action.payload
    },
    updateProductScore: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      const { name, value } = action.payload
      state.formData.products[name] = {
        ...state.formData.products[name],
        score: Number(value),
      }
    },
    updateProductReview: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      const { name, value } = action.payload
      state.formData.products[name] = {
        ...state.formData.products[name],
        description: value,
      }
    },
    confirmProductReview: (
      state,
      action: PayloadAction<InvitationOrderProduct>,
    ) => {
      const product = action.payload
      const productReview = state.formData.products[product.id]
      const { name, img_prod, score, description } = {
        ...product,
        ...productReview,
      }
      const completeProduct = { name, img_prod, score, description }

      if (
        productReview &&
        productReview.score &&
        productReview.score > 0 &&
        productReview.description &&
        productReview.description.length >= 3
      ) {
        state.formData.confirmedProducts = {
          ...state.formData.confirmedProducts,
          [product.id]: { ...completeProduct },
        }
        delete state.formData.errors[product.id]
      } else {
        state.formData.errors[product.id] =
          "Inserisci almeno 3 caratteri e seleziona una valutazione."
      }
    },
    updateErrors: (state, action: PayloadAction<Record<string, string>>) => {
      state.formData.errors = action.payload
    },
    resetPage: (state) => {
      return initialState
    },
  },
})

export const {
  setShopId,
  updateShopScores,
  updateProductScore,
  updateProductReview,
  confirmProductReview,
  updateShopReview,
  toggleShopIsAnonymous,
  updateErrors,
  updateImages,
  updateVideo,
  updateVideoUrl,
  resetPage,
} = sendReviewSlice.actions

export const selectSendReviewFormData = (state: RootState) =>
  state.sendReview.formData

export const selectSendReviewFormErrors = (state: RootState) =>
  state.sendReview.formData.errors

export const selectSendReviewConfirmedProducts = (state: RootState) =>
  state.sendReview.formData.confirmedProducts

export const selectSendReviewAverageScore = createSelector(
  (state: RootState) => state.sendReview.formData,
  (formData: SendReviewFormData) => {
    const scores = Object.values(formData.shop.scores);
    const nonZeroScores = scores.filter(score => score !== 0);
    const average = nonZeroScores.reduce((sum, field) => sum + field, 0) / nonZeroScores.length;
    return parseFloat(average.toFixed(1));
  }
)

export default sendReviewSlice.reducer
