import React from "react"
import { Link } from "react-router-dom"
import { Article as ArticleInterface } from "../../models/base/article.interface"

interface ArticleProps {
  article: ArticleInterface
}

export const Article: React.FC<ArticleProps> = ({
  article: { date, title, teaser, link, image_url },
}) => {
  return (
    <div className="col-12 my-0 my-md-3 p-3">
      <div className="background-white border-radius-75">
        <div
          className="background-image-fit height-170 border-radius-75 no-border-radius-bottom"
          style={{
            backgroundImage: `url(${image_url})`,
          }}
        ></div>
        <div className="p-3 background-white border-radius-75 relative-top-20">
          <h4>
            <Link
              to={link}
              className="line-clamp line-clamp-2 bolder-dark-title font-16"
              target="_blank"
              title={title}
            >
              {title}
            </Link>
          </h4>
          <p className="line-clamp line-clamp-4 dark-color font-14 min-height-84">
            {teaser}
          </p>
        </div>
      </div>
    </div>
  )
}
